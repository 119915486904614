import {
  ArrowPathIcon,
  CalendarDaysIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import validator from "validator";
import authService from "../services/auth.service";
import customerService from "../services/customer.service";
import PickupService from "../services/pickup.service";

const AddUserModal = (props) => {
  const { close, editUser, getUsers } = props;
  const [form, setForm] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleAddUser = () => {
    setLoading(true);
    setError();

    console.log(form);
    if (editUser) {
      form.id = editUser.id;
      authService
        .update(form)
        .then((resp) => {
          setForm({});
          setLoading(false);
          getUsers();
          close();
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    } else {
      authService
        .register(form)
        .then((resp) => {
          setForm({});
          setLoading(false);
          getUsers();
          close();
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (editUser) {
      setForm({
        first_name: editUser.first_name,
        last_name: editUser.last_name,
        role: editUser.role,
        email: editUser.email,
      });
    }
  }, []);

  return (
    <div
      id="modal"
      className={`py-12 bg-gray-700 bg-opacity-40 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0`}
    >
      <div
        role="alert"
        className={`container mx-auto w-11/12 md:w-2/3 max-w-lg`}
      >
        <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
          <div className="text-gray-800 text-2xl font-bold mb-3">
            {editUser ? "Edit" : "New"} User
          </div>
          <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">
            Enter User Details
          </h1>
          {error && (
            <div
              className="flex items-center space-x-2 mb-3 bg-red-500 bg-opacity-80 text-white text-sm font-bold px-4 py-3"
              role="alert"
            >
              <InformationCircleIcon className="h-4 w-4" />
              <p>{error}</p>
            </div>
          )}
          <label
            htmlFor="first_name"
            className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
          >
            Firstname
          </label>
          <input
            name="first_name"
            onChange={(e) => handleChange(e)}
            defaultValue={form.first_name}
            className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
            placeholder="Firstname"
          />
          <label
            htmlFor="last_name"
            className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
          >
            Lastname
          </label>
          <input
            name="last_name"
            onChange={(e) => handleChange(e)}
            defaultValue={form.last_name}
            className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
            placeholder="Lastname"
          />
          <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
            Login
          </label>
          <input
            name="email"
            onChange={(e) => handleChange(e)}
            defaultValue={form.email}
            className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
          />
          {!editUser && (
            <>
              <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                Password
              </label>
              <input
                name="password"
                type="password"
                onChange={(e) => handleChange(e)}
                defaultValue={form.password}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
              />
            </>
          )}

          <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
            Role
          </label>
          <select
            id="role"
            name="role"
            value={form.role}
            onChange={(e) => handleChange(e)}
            className="border border-gray-300 text-gray-900 text-sm block w-full p-2.5 mb-5 mt-2"
          >
            <option value="">Choose a Role</option>
            <option value="admin">Admin</option>
            <option value="manager">Manager</option>
          </select>
          <div className="flex items-center justify-start w-full">
            <button
              onClick={handleAddUser}
              disabled={loading}
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition duration-150 ease-in-out hover:bg-gray-800 bg-black rounded text-white px-8 py-2 text-sm"
            >
              {loading ? (
                <ArrowPathIcon className="h-6 w-6 mx-2 animate-spin" />
              ) : (
                "Submit"
              )}
            </button>
            <button
              className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
              onClick={close}
            >
              Cancel
            </button>
          </div>
          <button
            onClick={close}
            className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddUserModal;
