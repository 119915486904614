import React, { useState, useEffect } from "react";
import {
  TrashIcon,
  PencilIcon,
  PlusIcon,
  ArrowPathIcon,
  ClockIcon,
  CheckIcon,
  XMarkIcon,
  ShoppingCartIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import { format } from "date-fns";
import validator from "validator";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import OrderService from "../../services/order.service";
import OrderTotalCalculator from "../../components/OrderTotalCalculator";
import DropdownComponent from "../../components/DropdownComponent";
import ConfirmModalComponent from "../../components/ConfirmModal";
import CustomDropDown from "../../components/CustomDropDown";
import pickupService from "../../services/pickup.service";
import ConfirmPickupModalComponent from "../../components/ConfirmPickupModal";

function PickupRequests() {
  const [searchParams] = useSearchParams();
  const [openNewCModal, setOpenNewCModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [cancelRequest, setCancelRequest] = useState(false);
  const [confirmPickup, setConfirmPickup] = useState({});
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [filter, setFilter] = useState(searchParams.get("filter") || "");
  const [requestsCount, setRequestsCount] = useState();

  const [editCustomer, setEditCustomer] = useState({});
  const [dropRequests, setDropRequests] = useState([]);
  const [delCount, setDelCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [error, setError] = useState();
  const [listError, setListError] = useState();
  const [requestNumber, setRequestNumber] = useState("");

  const [form, setForm] = useState({});

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const navigatePages = (page, filter, request) => {
    // 👇️ navigate to /
    navigate(
      `/dashboard/pickups?page=${page}&filter=${filter}&request=${request}`
    );
    setPage(page);
    getRequests(page, filter, request);
  };
  const handleSelectRequest = (index) => {
    dropRequests[index].isChecked = !dropRequests[index].isChecked;
    setDropRequests(dropRequests);
    setDelCount(
      dropRequests.filter((request) => request.isChecked === true).length
    );
  };
  const getRequests = (page, filter, request) => {
    setListLoading(true);
    setListError(false);
    pickupService
      .getRequests(filter, page || 1, request)
      .then((resp) => {
        setDropRequests(resp.requests.requests);
        setRequestsCount(resp.requests.count);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
        console.log(err);
      });
  };
  useEffect(() => {
    if (searchParams.get("request"))
      setRequestNumber(searchParams.get("request"));
    getRequests(
      searchParams.get("page"),
      searchParams.get("filter"),
      searchParams.get("request")
    );
  }, []);
  return (
    <div className="">
      <div className="flex space-x-4 my-4">
        <h1 className="text-2xl mb-4 flex-1">Pickup Requests</h1>
        <div className="flex-1 ml-5 flex">
          <input
            className="h-10 border border-gray-400 p-2 w-96"
            placeholder="Search with request number..."
            type="number"
            onChange={(e) => {
              setRequestNumber(e.target.value);
              navigatePages(1, "", e.target.value);
            }}
            defaultValue={requestNumber}
          />
        </div>
        {delCount > 0 && (
          <button className="flex space-x-2 text-red-600 hover:text-gray-800 py-2 px-3 rounded focus:outline-none focus:shadow-outline text-sm items-center">
            <ClockIcon className="h-4 w-4" />
            <p>Schedule {delCount} selected</p>
          </button>
        )}
        <Link
          to={"/dashboard/pickup/create"}
          className="flex space-x-2 items-center bg-black hover:bg-gray-800 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
        >
          <PlusIcon className="h-4 w-4" />
          <p>Create Pickup</p>
        </Link>
      </div>
      <div className="w-full overflow-x-scroll">
        <table className="table-auto w-full mb-3 border border-gray-200">
          <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
            <tr>
              <th className="p-2">
                <div className="font-semibold text-left">Pickup ID</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Request Date</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Customer Name</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Pair Estimate</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Discount Code</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Type</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Status</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">
                  Preferred Pickup Date
                </div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Action</div>
              </th>
            </tr>
          </thead>

          <tbody className="text-sm divide-y divide-gray-100">
            {listLoading ? (
              <tr>
                <td colSpan={8} className="">
                  <ArrowPathIcon className="h-6 w-6 mx-auto animate-spin" />
                </td>
              </tr>
            ) : listError ? (
              <tr>
                <td colSpan={8} className="">
                  <p className="text-center">{listError}</p>
                </td>
              </tr>
            ) : (
              dropRequests.map((pickup, index) => (
                <tr key={index}>
                  {/* <td className="p-2">
                    {pickup.status === "pending" && (
                      <input
                        type="checkbox"
                        className="w-5 h-5 cursor-pointer"
                        onChange={() => handleSelectRequest(index)}
                      />
                    )}
                  </td> */}
                  <td className="p-2">
                    <Link
                      to={"/dashboard/pickup/" + pickup.id}
                      className="text-left font-bold cursor-pointer"
                    >
                      #{pickup.number}
                    </Link>
                  </td>
                  <td className="p-2">
                    <div className="text-left">
                      {format(new Date(pickup.createdAt), "dd MMM. yyyy")}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="font-medium text-gray-800">
                      {pickup.customer.firstName} {pickup.customer.lastName}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="font-medium text-gray-800">
                      {pickup.pairsEstimate}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="font-medium text-gray-800">
                      {pickup.discountCode}
                    </div>
                  </td>
                  <td className="p-2 capitalize">{pickup.logisticsOption}</td>
                  <td className="p-2">
                    <div className="text-left">
                      <span
                        className={`text-xs font-semibold py-1 px-2 uppercase rounded  ${
                          pickup.status === "cancelled"
                            ? "text-gray-600 bg-gray-200"
                            : pickup.status === "scheduled"
                            ? "text-yellow-600 bg-yellow-200"
                            : pickup.status === "pending"
                            ? "text-red-600 bg-red-200"
                            : "text-green-600 bg-green-200"
                        } uppercase`}
                      >
                        {pickup.status}
                      </span>
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-left">
                      {format(new Date(pickup.preferredDate), "dd MMM. yyyy")}
                    </div>
                  </td>
                  <td className="p-2 flex items-center gap-2">
                    {pickup.status !== "collected" &&
                      pickup.status !== "cancelled" && (
                        <div className="bg-white border rounded-md">
                          <Link
                            to={"/dashboard/pickup/" + pickup.id + "/edit"}
                            className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-md"
                          >
                            <PencilIcon className="h-4 w-4" />
                            <p>Edit</p>
                          </Link>
                        </div>
                      )}
                    {pickup.status === "pending" && (
                      <div className="bg-white border rounded-md">
                        <button
                          onClick={() => {
                            setConfirmPickup(pickup);
                            setOpenConfirmModal(true);
                          }}
                          className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-md"
                        >
                          <ClockIcon className="h-4 w-4" />

                          <p>Mark as Scheduled</p>
                        </button>
                      </div>
                    )}

                    {pickup.status === "scheduled" && (
                      <Link
                        to={"/dashboard/order/create/" + pickup.id}
                        className="hover:bg-gray-100 px-3 py-2 flex items-center space-x-1 border"
                      >
                        <ShoppingCartIcon className="h-4 w-4" />{" "}
                        <span>Create Order</span>
                      </Link>
                    )}
                    {pickup.status !== "collected" &&
                      pickup.status !== "cancelled" && (
                        <div className="bg-white border rounded-md">
                          <button
                            onClick={() => {
                              setConfirmPickup(pickup);
                              setOpenConfirmModal(true);
                              setCancelRequest(true);
                            }}
                            className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-md"
                          >
                            <XMarkIcon className="h-4 w-4" />
                            <p>Cancel</p>
                          </button>
                        </div>
                      )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex  items-center">
        <button
          onClick={() => navigatePages(!page ? 2 : parseInt(page) - 1, filter)}
          disabled={!page || parseInt(page) === 1}
          className="bg-white border border-gray-300 p-1 hover:bg-gray-100 disabled:bg-gray-100"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <button
          onClick={() => navigatePages(!page ? 2 : parseInt(page) + 1, filter)}
          disabled={page * 20 >= requestsCount}
          className="bg-white border border-gray-300 p-1 hover:bg-gray-100 disabled:bg-gray-100"
        >
          <ChevronRightIcon className="h-6 w-6" />
        </button>
        <small className="text-right flex-1">
          Page {page}/{Math.ceil(requestsCount / 20)} - {requestsCount} results
        </small>
      </div>

      {openConfirmModal && (
        <ConfirmPickupModalComponent
          request={confirmPickup}
          getRequests={getRequests}
          cancelRequest={cancelRequest}
          setCancelRequest={setCancelRequest}
          setConfirm={setOpenConfirmModal}
        />
      )}
    </div>
  );
}
export default PickupRequests;
