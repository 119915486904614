import React, { useState, useEffect } from "react";
import {
  PlusIcon,
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import OrderService from "../../services/order.service";
import ActionMenuComponent from "../../components/ActionMenuComponent";
import BulkActionMenuComponent from "../../components/BulkActionMenuComponent";
import moment from "moment";

function Orders() {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [filter, setFilter] = useState(searchParams.get("filter") || "");
  const [ordersList, setOrdersList] = useState([]);
  const [ordersCount, setordersCount] = useState();
  const [orderNumber, setOrderNumber] = useState("");
  const [delCount, setDelCount] = useState(0);
  const [listLoading, setListLoading] = useState(false);
  const [listError, setListError] = useState();
  const navigate = useNavigate();

  const handleSelectOrder = (index) => {
    ordersList[index].isChecked = !ordersList[index].isChecked;
    setOrdersList(ordersList);
    setDelCount(ordersList.filter((order) => order.isChecked === true).length);
  };
  const navigatePages = (page, filter, order) => {
    // 👇️ navigate to /
    navigate(`/dashboard/orders?page=${page}&filter=${filter}&order=${order}`);
    setPage(page);
    setFilter(filter);
    getOrders(page, filter, order);
  };
  const getOrders = (page, filter, order, customerId) => {
    setListLoading(true);
    setListError(false);
    OrderService.getOrders(filter, page || 1, order, customerId)
      .then((resp) => {
        setOrdersList(resp.orders);
        setordersCount(resp.count);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
        console.log(err);
      });
  };
  useEffect(() => {
    if (searchParams.get("order")) setOrderNumber(searchParams.get("order"));
    getOrders(
      searchParams.get("page"),
      searchParams.get("filter") || "",
      searchParams.get("order") || "",
      searchParams.get("customerId") || ""
    );
  }, []);
  return (
    <div className="">
      <div className="flex flex-col space-y-4 mb-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl">Orders</h1>
          {ordersList.filter((order) => order.isChecked === true).length >
            0 && (
            // <button className="flex space-x-2 text-gray-800 border border-gray-300 hover:text-gray-800 py-2 px-3 rounded focus:outline-none focus:shadow-outline text-sm items-center">
            //   {/* <TrashIcon className="h-4 w-4" /> */}
            //   <p>Mark { ordersList.filter((order) => order.isChecked === true).length} as</p>
            // </button>
            <BulkActionMenuComponent
              getOrders={() => getOrders(page)}
              orders={ordersList.filter((order) => order.isChecked === true)}
            />
          )}
          <Link
            to={"/dashboard/order/create"}
            className="flex space-x-2 items-center bg-black hover:bg-gray-800 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
          >
            <PlusIcon className="h-4 w-4" />
            <p>Create Order</p>
          </Link>
        </div>
        <div className="flex w-full">
          <input
            className="h-10 border border-gray-400 flex-1 p-2"
            placeholder="Search with order number..."
            type="number"
            onChange={(e) => {
              setOrderNumber(e.target.value);
              navigatePages(1, "", e.target.value);
            }}
            defaultValue={orderNumber}
          />
          <button
            className="h-10 bg-black text-white p-3"
            onClick={() => navigatePages(1, "", orderNumber)}
          >
            <MagnifyingGlassIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
      <div className="w-full">
        <ul className="flex divide-x mb-2">
          {["", "confirmed", "ready", "picked_up", "delivered"].map(
            (fil, index) => (
              <li
                key={index}
                className={`px-2 capitalize text-sm cursor-pointer ${
                  fil === filter && "text-red-600 underline"
                }`}
                onClick={() => navigatePages(1, fil, orderNumber)}
              >
                {fil.replace("_", " ") || "All"}
              </li>
            )
          )}
        </ul>
        <div className="hidden md:block">
          <table className="table-auto w-full mb-3 border border-gray-200">
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left"></div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Order</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Payment Status</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Order Status</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Date</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Customer</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Drop Date</div>
                </th>
                <th className="p-2 max-md:hidden">
                  <div className="font-semibold text-left">Return Date</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Total</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Action</div>
                </th>
              </tr>
            </thead>

            <tbody className="text-sm divide-y divide-gray-100">
              {listLoading ? (
                <tr>
                  <td colSpan={9} className="">
                    <ArrowPathIcon className="h-6 w-6 mx-auto animate-spin" />
                  </td>
                </tr>
              ) : listError ? (
                <tr>
                  <td colSpan={9} className="">
                    <p className="text-center">{listError}</p>
                  </td>
                </tr>
              ) : (
                ordersList.map((order, index) => (
                  <tr
                    key={index}
                    className={`h-6 hover:bg-teal-100 hover:bg-opacity-50 ${
                      order.isChecked && "bg-gray-200"
                    } ${
                      (order.status === "delivered" ||
                        order.status === "cancelled") &&
                      "bg-gray-100"
                    } ${order.status === "cancelled" && "opacity-50"}`}
                  >
                    <td className="p-2">
                      {order.status === "draft" ? (
                        ""
                      ) : order.status === "cancelled" ? (
                        ""
                      ) : order.status === "delivered" ? (
                        ""
                      ) : (
                        <input
                          type="checkbox"
                          className="w-5 h-5 cursor-pointer"
                          onChange={() => handleSelectOrder(index)}
                          value="id-1"
                        />
                      )}
                    </td>
                    <td className="p-2 flex flex-col">
                      <Link
                        to={"/dashboard/order/" + order.id}
                        className="text-left font-bold cursor-pointer"
                      >
                        #{order.number}
                      </Link>
                      {moment(new Date(order.return.date)).diff(
                        new Date(),
                        "days"
                      ) < 2 &&
                        order.status !== "cancelled" &&
                        order.status !== "delivered" && (
                          <small className="text-red-500">
                            {" "}
                            Due{" "}
                            {moment().diff(
                              moment(new Date(order.return.date)),
                              "hours"
                            ) < 24
                              ? moment(new Date(order.return.date)).calendar(
                                  null,
                                  {
                                    sameDay: "[Today]",
                                    nextDay: "[Tomorrow]",
                                    nextWeek: "dddd",
                                    lastDay: "[Yesterday]",
                                    lastWeek: "[Last] dddd",
                                    sameElse: "MM/DD/YYYY",
                                  }
                                )
                              : moment(new Date(order.return.date)).fromNow()}
                          </small>
                        )}
                    </td>
                    <td className="p-2">
                      <div className="text-left">
                        <span
                          className={`text-xs font-semibold py-1 px-2 uppercase rounded  ${
                            !order.paymentMethod
                              ? "text-yellow-800 bg-yellow-200"
                              : "text-green-600 bg-green-200"
                          } uppercase`}
                        >
                          {!order.paymentMethod ? "Unpaid" : "Paid"}
                        </span>
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-left">
                        <span
                          className={`text-xs font-semibold py-1 px-2 uppercase rounded  ${
                            order.status === "draft"
                              ? "text-gray-600 bg-gray-200"
                              : order.status === "cancelled"
                              ? "text-red-600 bg-red-200"
                              : order.status === "delivered"
                              ? "text-gray-600 bg-gray-200"
                              : "text-green-600 bg-green-200"
                          } uppercase`}
                        >
                          {order.status.replace("_", " ")}
                        </span>
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-left">
                        {format(new Date(order.createdAt), "dd MMM. yyyy")}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="font-medium text-gray-800">
                        {order.customer.firstName} {order.customer.lastName}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-left">
                        {format(new Date(order.drop.date), "dd MMM. yyyy")}
                      </div>
                    </td>
                    <td className="p-2 max-md:hidden">
                      <div className="text-left">
                        {format(new Date(order.return.date), "dd MMM. yyyy")}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="font-medium text-gray-800">
                        &#8358;
                        {(order.amount / 100).toLocaleString()}
                      </div>
                    </td>
                    <td className="p-2 flex items-center gap-2">
                      {order.status !== "cancelled" && (
                        <ActionMenuComponent
                          order={order}
                          orders={ordersList}
                          setOrders={setOrdersList}
                        />
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="md:hidden">
          {listLoading ? (
            <ArrowPathIcon className="h-6 w-6 mx-auto animate-spin" />
          ) : listError ? (
            <p className="text-center">{listError}</p>
          ) : (
            <ul className="divide-y divide-gray-200 border border-gray-200">
              {ordersList.map((order, index) => {
                return (
                  <li
                    className={`flex p-2 hover:bg-teal-100 hover:bg-opacity-50 ${
                      order.isChecked && "bg-gray-200"
                    } ${
                      (order.status === "delivered" ||
                        order.status === "cancelled") &&
                      "bg-gray-100"
                    } ${order.status === "cancelled" && "opacity-50"}`}
                    key={index}
                  >
                    <input
                      type="checkbox"
                      className="w-4 h-4 cursor-pointer"
                      disabled={
                        order.status === "draft"
                          ? true
                          : order.status === "cancelled"
                          ? true
                          : order.status === "delivered"
                          ? true
                          : false
                      }
                      onChange={() => handleSelectOrder(index)}
                      value="id-1"
                    />
                    <div className="flex-1 flex flex-col pl-3 space-y-1">
                      <Link to={"/dashboard/order/" + order.id}>
                        <div className="flex flex-col" key={index}>
                          {/* <div className="flex items-center space-x-2 text-xs my-1"> */}

                          <div className="flex items-start space-x-1 text-xs">
                            <small className="text-gray-500">
                              {format(
                                new Date(order.createdAt),
                                "dd MMM. yyyy"
                              )}
                            </small>
                            {moment(new Date(order.return.date)).diff(
                              new Date(),
                              "days"
                            ) < 2 &&
                              order.status !== "cancelled" &&
                              order.status !== "delivered" && (
                                <small className="text-red-500">
                                  Due{" "}
                                  {moment().diff(
                                    moment(new Date(order.return.date)),
                                    "hours"
                                  ) < 24
                                    ? moment(
                                        new Date(order.return.date)
                                      ).calendar(null, {
                                        sameDay: "[Today]",
                                        nextDay: "[Tomorrow]",
                                        nextWeek: "dddd",
                                        lastDay: "[Yesterday]",
                                        lastWeek: "[Last] dddd",
                                        sameElse: "MM/DD/YYYY",
                                      })
                                    : moment(
                                        new Date(order.return.date)
                                      ).fromNow()}
                                </small>
                              )}
                          </div>
                          <div className="text-sm font-medium text-black items-center flex justify-between">
                            <h5 className="text-left text-sm font-bold cursor-pointer">
                              #{order.number}
                            </h5>
                            <div className="text-xs my-1 space-x-1">
                              <span
                                className={`px-1.5 py-1 text-xs font-medium uppercase rounded bg-opacity-50  ${
                                  !order.paymentMethod
                                    ? "text-yellow-800 bg-yellow-200"
                                    : "text-green-600 bg-green-200"
                                } uppercase`}
                              >
                                {!order.paymentMethod ? "Unpaid" : "Paid"}
                              </span>
                              <span
                                className={`px-1.5 py-1 text-xs font-medium uppercase rounded bg-opacity-50  ${
                                  order.status === "draft"
                                    ? "text-gray-600 bg-gray-200"
                                    : order.status === "cancelled"
                                    ? "text-red-600 bg-red-200"
                                    : order.status === "delivered"
                                    ? "text-gray-600 bg-gray-200"
                                    : "text-green-600 bg-green-200"
                                } uppercase`}
                              >
                                {order.status.replace("_", " ")}
                              </span>
                            </div>
                          </div>
                          {/* </div> */}

                          <div>
                            {order.customer.firstName} {order.customer.lastName}
                          </div>
                          <h5 className="text-left text-sm font-bold cursor-pointer">
                            &#8358;
                            {(order.amount / 100).toLocaleString()}
                          </h5>
                          <div className="text-gray-500 text-xs">
                            {format(new Date(order.drop.date), "dd MMM. yyyy") +
                              " - "}
                            {format(
                              new Date(order.return.date),
                              "dd MMM. yyyy"
                            )}
                          </div>
                        </div>
                      </Link>
                      {order.status !== "cancelled" && (
                        <ActionMenuComponent
                          order={order}
                          orders={ordersList}
                          setOrders={setOrdersList}
                        />
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      <div className="flex  items-center">
        <button
          onClick={() =>
            navigatePages(!page ? 2 : parseInt(page) - 1, filter, orderNumber)
          }
          disabled={!page || parseInt(page) === 1}
          className="bg-white border border-gray-300 p-1 hover:bg-gray-100 disabled:bg-gray-100"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <button
          onClick={() =>
            navigatePages(!page ? 2 : parseInt(page) + 1, filter, orderNumber)
          }
          disabled={page * 20 >= ordersCount}
          className="bg-white border border-gray-300 p-1 hover:bg-gray-100 disabled:bg-gray-100"
        >
          <ChevronRightIcon className="h-6 w-6" />
        </button>
        <small className="text-right flex-1">
          Page {page}/{Math.ceil(ordersCount / 20)} - {ordersCount} results
        </small>
      </div>
    </div>
  );
}
export default Orders;
