import {
  ArrowPathIcon,
  BellAlertIcon, ChevronDownIcon
} from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import OrderService from "../services/order.service";

export default function BulkActionMenuComponent(props) {
  const {  orders, getOrders } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const dropDownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [doAction, setDoAction] = useState();

  const handleClickOutsideDropDown = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleUpdateStatus = (status) => {
    setLoading(true);
    setOpenConfirmModal(false);
    setIsOpen(false);
    let request = {
      orders: [],
      status: status,
    };
    orders.map((order) => request.orders.push(order.id));
    OrderService.bulkUpdate(request)
      .then((response) => {
        setLoading(false);
        console.log(response);
        getOrders();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropDown, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropDown, true);
    };
  }, []);
  return (
    <div className="relative bg-white border text-gray-600 rounded-md">
      <button
        onClick={() => setIsOpen(!isOpen)}
        disabled={loading}
        className="flex items-center gap-2 px-4 py-2 text-sm hover:text-gray-300 rounded-l-md"
      >
        {loading ? (
          <ArrowPathIcon className="animate-spin h-4 w-4" />
        ) : (
          "Mark " + orders.length + " as"
        )}
        <ChevronDownIcon className="h-4 w-4" />
      </button>

      <div
        ref={dropDownRef}
        className={`${
          isOpen ? "" : "hidden"
        } absolute right-0 z-10 w-36 mt-2 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg`}
      >
        <button
          onClick={() => {
            setOpenConfirmModal(true);
            setDoAction("ready");
          }}
          className="block w-full px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
        >
          <p>Ready</p>
        </button>
        <button
          onClick={() => {
            setOpenConfirmModal(true);
            setDoAction("picked_up");
          }}
          className="block w-full px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
        >
          <p>Picked Up</p>
        </button>
        <button
          onClick={() => {
            setOpenConfirmModal(true);
            setDoAction("delivered");
          }}
          className="block w-full px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
        >
          <p>Delivered</p>
        </button>
        {/* <button
          onClick={() => {
            setOpenConfirmModal(true);
            setDoAction("pay");
          }}
          className="block w-full px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
        >
          <p>Paid</p>
        </button> */}
      </div>
      {openConfirmModal && (
        <div
          className={`relative z-10`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <BellAlertIcon className="w-6 h-6" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-lg font-medium leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Confirm bulk action
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to perform this action? This
                          cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={() => handleUpdateStatus(doAction)}
                    disabled={loading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {!loading ? (
                      "Continue"
                    ) : (
                      <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => setOpenConfirmModal(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
