import {
  ArrowPathIcon,
  CheckIcon,
  ChevronDownIcon,
  CreditCardIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import OrderService from "../services/order.service";
import ConfirmActionModalComponent from "./ConfirmActionModal";

export default function ActionMenuComponent(props) {
  const { order, orders, setOrders } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const dropDownRef = useRef(null);
  const dropButton = useRef(null);
  const [loading, setLoading] = useState(false);
  const [doAction, setDoAction] = useState();

  const handleClickOutsideDropDown = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target) && !dropButton.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleUpdateStatus = (status) => {
    return function () {
      setLoading(true);
      setOpenConfirmModal(false);
      setIsOpen(false);
      let request = {
        id: order.id,
        status: status,
      };
      OrderService.updateOrder(request)
        .then((response) => {
          setLoading(false);
          console.log(response);
          const updated = orders.map((orderResp) => {
            if (orderResp.id === order.id) {
              return { ...orderResp, status: status };
            } else {
              return orderResp;
            }
          });
          setOrders(updated);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    };
  };

  const handlePayOrder = () => {
    setLoading(true);
    setOpenConfirmModal(false);
    setIsOpen(false);

    OrderService.payOrder({ paymentMethod: "transfer" }, order.id)
      .then((resp) => {
        setLoading(false);
        setOpenConfirmModal(false);
        const updated = orders.map((o) => {
          if (o.id === order.id) {
            return { ...o, paymentMethod: "transfer" };
          } else {
            return o;
          }
        });
        console.log(updated);
        setOrders(updated);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropDown, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropDown, true);
    };
  }, []);
  return (
    <div className="relative bg-white border text-gray-600 rounded-md">
      <button
        onClick={() => setIsOpen(!isOpen)}
        ref={dropButton}
        disabled={loading}
        className="flex items-center gap-2 px-4 py-2 text-sm hover:text-gray-300 rounded-l-md justify-between w-full"
      >
        {loading ? (
          <ArrowPathIcon className="animate-spin h-4 w-4" />
        ) : (
          "Action"
        )}
        <ChevronDownIcon className="h-4 w-4" />
      </button>

      <div
        ref={dropDownRef}
        className={`${
          isOpen ? "" : "hidden"
        } absolute right-0 z-10 w-36 mt-2 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg`}
      >
        <Link
          to={"/dashboard/order/" + order.id + "/edit"}
          className="block w-full px-2 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
        >
          <PencilIcon className="h-4 w-4" />
          <p>Edit</p>
        </Link>
        {order.status === "confirmed" ? (
          <button
            onClick={() => {
              setOpenConfirmModal(true);
              setDoAction("ready");
            }}
            className="block w-full px-2 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
          >
            <CheckIcon className="h-4 w-4" />
            <p>Ready</p>
          </button>
        ) : order.status === "ready" ? (
          <button
            onClick={() => {
              setOpenConfirmModal(true);
              setDoAction("picked_up");
            }}
            className="block w-full px-2 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
          >
            <CheckIcon className="h-4 w-4" />
            <p>Picked Up</p>
          </button>
        ) : (
          order.status === "picked_up" && (
            <button
              onClick={() => {
                setOpenConfirmModal(true);
                setDoAction("delivered");
              }}
              className="block w-full px-2 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
            >
              <CheckIcon className="h-4 w-4" />
              <p>Delivered</p>
            </button>
          )
        )}
        {!order.paymentMethod && (
          <button
            onClick={() => {
              setOpenConfirmModal(true);
              setDoAction("pay");
            }}
            className="block w-full px-2 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
          >
            <CreditCardIcon className="h-4 w-4" />
            <p>Paid</p>
          </button>
        )}
        {(order.status === "draft" || order.status === "confirmed") && (
          <button
            onClick={() => {
              setOpenConfirmModal(true);
              setDoAction("cancelled");
            }}
            className="block w-full px-2 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 flex items-center gap-1"
          >
            <XMarkIcon className="h-4 w-4" />
            <p>Cancel</p>
          </button>
        )}
      </div>
      {openConfirmModal && (
        <ConfirmActionModalComponent
          order={order}
          action={
            doAction === "pay" ? handlePayOrder : handleUpdateStatus(doAction)
          }
          setConfirm={setOpenConfirmModal}
        />
      )}
    </div>
  );
}
