import React, { forwardRef, useState, useEffect, useRef } from "react";
import {
  TrashIcon,
  UserCircleIcon,
  PlusIcon,
  ShoppingCartIcon,
  MinusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  CalendarDaysIcon,
  XMarkIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import validator from "validator";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import OrderService from "../../services/order.service";
import OrderTotalCalculator from "../../components/OrderTotalCalculator";
import AddCustomerModal from "../../components/AddCustomerModal";
import AddressPicker from "../../components/AddressPicker";
import PickupService from "../../services/pickup.service";
import BackButton from "../../components/BackButton";

function EditPickup() {
  let { requestId } = useParams();
  const [locations, setLocations] = useState([]);
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [addressModal, setAddressModal] = useState("");
  const [selectedAddresses, setSelectedAddresses] = useState({});
  const [preferredDate, setPreferredDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [customer, setCustomer] = useState();
  const [loading, setLoading] = useState(false);
  const [pairsEstimate, setPairsEstimate] = useState(2);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [logisticsOption, setLogisticsOption] = useState();
  const [pickupFee, setPickupFee] = useState();

  const navigate = useNavigate();

  const ref = useRef(null);

  const handleUpdateRequest = (confirm) => {
    setLoading(true);
    const request = {
      id: requestId,
      address: selectedAddresses.drop,
      pairsEstimate: pairsEstimate,
      pickupFee,
      preferredDate: preferredDate.toISOString().split("T")[0], // ISO 8601 format : YYYY-MM-DD
    };
    console.log(request);
    PickupService.updateRequest(request)
      .then((resp) => {
        setLoading(false);
        navigate("/dashboard/pickups");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClickOutsideSearch = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenSuggestions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSearch, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideSearch, true);
    };
  }, []);

  const getRequest = () => {
    setLoading(true);
    PickupService.getRequest(requestId)
      .then((resp) => {
        setCustomer(resp.customer);
        setPreferredDate(new Date(resp.preferredDate));
        setLogisticsOption(resp.logisticsOption);
        setPickupFee(resp.pickupFee);
        setSelectedAddresses({
          drop: resp.address,
        });
        setPairsEstimate(resp.pairsEstimate);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getRequest();
    PickupService.getLocations()
      .then((resp) => {
        setLocations(resp.locations);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <div className="">
      <div className="my-4 flex items-center space-x-4 flex-wrap">
        <BackButton />
        <h1 className="text-2xl my-4 ">Edit Request</h1>
      </div>
      <div className="flex flex-col xl:flex-row w-full lg:w-3/4 2xl:w-2/3 mx-auto gap-2 justify-center">
        <div className="w-full xl:w-2/3 flex flex-col space-y-2">
          <div className="border border-gray-300 p-5">
            {customer && (
              <div className="">
                <div className="flex justify-between">
                  <h1 className="text-lg font-medium">
                    {customer.firstName} {customer.lastName}
                  </h1>
                </div>
                <p>{customer.phone}</p>
              </div>
            )}
          </div>
          {customer && (
            <div className="border border-gray-300 p-5">
              <div className="flex space-x-2">
                <div className="w-full flex flex-col gap-2">
                  <h4 className="text-lg mb-2">Pickup Details</h4>

                  <p className="mb-2 font-bold">Date</p>
                  <div className="relative w-full mb-2">
                    <DatePicker
                      selected={preferredDate}
                      name="preferredDate"
                      onChange={(date) => {
                        setPreferredDate(date);
                      }}
                      selectsStart
                      startDate={preferredDate}
                      minDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      popperClassName="react-datepicker-left"
                      customInput={<ButtonInput />}
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div className="flex items-center justify-between px-2 py-2">
                          <span className="text-lg text-gray-700">
                            {format(date, "MMMM yyyy")}
                          </span>

                          <div className="space-x-2">
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                              type="button"
                              className={`
                                            ${
                                              prevMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                            >
                              <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                            </button>

                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                              type="button"
                              className={`
                                            ${
                                              nextMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                            >
                              <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <p className="mb-2 font-bold">Address</p>
                  {selectedAddresses.drop ? (
                    <div>
                      <p className="font-bold">
                        {selectedAddresses.drop.firstName}{" "}
                        {selectedAddresses.drop.lastName}
                      </p>
                      <p>
                        {selectedAddresses.drop.address1},{" "}
                        {selectedAddresses.drop.address2}
                      </p>
                      <p>{selectedAddresses.drop.city}</p>
                      <p>+{selectedAddresses.drop.phone}</p>
                      <button
                        className="text-sm text-red-600 hover:underline"
                        onClick={() => setAddressModal("drop")}
                      >
                        Change
                      </button>
                    </div>
                  ) : (
                    <div>
                      <p>No default address</p>
                      <button
                        className="text-sm text-red-600 hover:underline"
                        onClick={() => setAddressModal("drop")}
                      >
                        Select
                      </button>
                    </div>
                  )}
                  <p className="mb-2 font-bold"># of Pairs</p>
                  <input
                    name="pairsEstimate"
                    onChange={(e) => setPairsEstimate(e.target.value)}
                    defaultValue={pairsEstimate}
                    placeholder="10"
                    className="w-full border border-gray-300 p-2"
                  />
                  <div className="mt-5 ">
                    <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                      Pickup Price Estimate{" "}
                      <small>(select preferred option)</small>
                    </label>
                    {locations.length > 1 && (
                      <div className="mt-2">
                        <div className="form-check">
                          <input
                            className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-red-600 checked:border-red-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio"
                            value="bike"
                            id="bike"
                            checked={logisticsOption === "bike"}
                            onChange={(e) => setLogisticsOption(e.target.value)}
                            name="logisticsOption"
                          />
                          <label
                            htmlFor="bike"
                            className="form-check-label inline-block text-gray-800"
                          >
                            Bike (&#8358;
                            {parseInt(
                              locations[selectedLocation].pricing.bike *
                                (pairsEstimate % 10
                                  ? Math.floor(pairsEstimate / 10) + 1
                                  : Math.floor(pairsEstimate / 10))
                            ).toLocaleString()}{" "}
                            - &#8358;
                            {(
                              parseInt(
                                locations[selectedLocation].pricing.bike *
                                  (pairsEstimate % 10
                                    ? Math.floor(pairsEstimate / 10) + 1
                                    : Math.floor(pairsEstimate / 10))
                              ) + 500
                            ).toLocaleString()}
                            )
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-red-600 checked:border-red-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio"
                            id="van"
                            value="van"
                            checked={logisticsOption === "van"}
                            onChange={(e) => setLogisticsOption(e.target.value)}
                            name="logisticsOption"
                          />
                          <label
                            htmlFor="van"
                            className="form-check-label inline-block text-gray-800"
                          >
                            Cab/Mini-Van (&#8358;
                            {parseInt(
                              locations[selectedLocation].pricing.van
                            ).toLocaleString()}{" "}
                            - &#8358;
                            {(
                              parseInt(
                                locations[selectedLocation].pricing.van
                              ) + 1000
                            ).toLocaleString()}
                            )
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-red-600 checked:border-red-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio"
                            id="dropoff"
                            value="dropoff"
                            checked={logisticsOption === "dropoff"}
                            onChange={(e) => setLogisticsOption(e.target.value)}
                            name="logisticsOption"
                          />
                          <label
                            htmlFor="dropoff"
                            className="form-check-label inline-block text-gray-800"
                          >
                            Drop-off (FREE)
                          </label>
                        </div>
                      </div>
                    )}
                    <p className="text-sm mt-2">
                      Please note that the prices provided are only estimates
                      and do not include the cost of delivery. The final cost
                      will be confirmed and communicated to you once your
                      request has been processed.
                    </p>
                  </div>
                  <p className="mb-2 font-bold">Pickup Fee</p>
                  <input
                    name="pickupFee"
                    onChange={(e) => setPickupFee(e.target.value)}
                    defaultValue={pickupFee}
                    placeholder="10"
                    className="w-full border border-gray-300 p-2"
                  />
                </div>
              </div>
              {/* <div className="flex items-center justify-center w-full mx-auto space-x-2 mt-2">
            
            </div> */}
            </div>
          )}

          <button
            onClick={() => handleUpdateRequest(true)}
            disabled={!customer || loading}
            className="bg-black w-full text-white p-2 mt-2 text-center disabled:opacity-75"
          >
            {loading ? (
              <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
            ) : (
              "Save"
            )}
          </button>
        </div>
        {addressModal !== "" && (
          <AddressPicker
            type={addressModal}
            customer={customer}
            selectedAddresses={selectedAddresses}
            setSelectedAddresses={setSelectedAddresses}
            close={() => setAddressModal("")}
          />
        )}
      </div>
    </div>
  );
}
const ButtonInput = forwardRef(({ value, onClick }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
  >
    {format(new Date(value), "dd MMMM yyyy")}
  </button>
));
export default EditPickup;
