import React, { useState, useEffect } from "react";
import {
  TrashIcon,
  PencilIcon,
  XMarkIcon,
  InformationCircleIcon,
  PlusIcon,
  ArrowPathIcon,
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import validator from "validator";
import AddCustomerModal from "../../components/AddCustomerModal";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

function Customers() {
  const [searchParams] = useSearchParams();
  const [openNewCModal, setOpenNewCModal] = useState(false);
  const [openEditCModal, setOpenEditCModal] = useState(false);
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [perPage, setPerPage] = useState(searchParams.get("perPage") || 1);
  const [filter, setFilter] = useState(searchParams.get("filter") || "");
  const [customerCount, setcustomerCount] = useState();
  const [editCustomer, setEditCustomer] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [delCount, setDelCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [error, setError] = useState();
  const [listError, setListError] = useState();
  const navigate = useNavigate();

  const [form, setForm] = useState({});

  const auth = useSelector((state) => state.auth);

  const toggleNewCModal = () => {
    setOpenNewCModal(!openNewCModal);
  };
  const toggleEditCModal = (customer) => {
    setEditCustomer(customer);
    setOpenEditCModal(!openEditCModal);
  };

  const navigatePages = (page, filter) => {
    // 👇️ navigate to /
    navigate(`/dashboard/customers?page=${page}&filter=${filter}`);
    setPage(page);
    getCustomers(filter, 20, page);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditCustomer({ ...editCustomer, [name]: value });
  };
  const handleSelectCustomer = (index) => {
    customerList[index].isChecked = !customerList[index].isChecked;
    setCustomerList(customerList);
    setDelCount(
      customerList.filter((customer) => customer.isChecked === true).length
    );
  };

  const handleAddCustomer = () => {
    setLoading(true);
    setError();
    if (!form.firstName || !form.phone || !form.email) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (
      validator.isEmpty(form.firstName) ||
      validator.isEmpty(form.phone) ||
      validator.isEmpty(form.email)
    ) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (!validator.isEmail(form.email)) {
      setError("This is not a valid email");
      setLoading(false);
      return;
    }
    CustomerService.newCustomer(form)
      .then((resp) => {
        setForm({});
        setLoading(false);

        toggleNewCModal();
        getCustomers("", 20, 1);
      })
      .catch((err) => {
        setError(err.response.data);
        setLoading(false);
      });
  };
  const handleEditCustomer = () => {
    setLoading(true);
    setError();
    if (!editCustomer.firstName || !editCustomer.phone || !editCustomer.email) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (
      validator.isEmpty(editCustomer.firstName) ||
      validator.isEmpty(editCustomer.phone) ||
      validator.isEmpty(editCustomer.email)
    ) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (!validator.isEmail(editCustomer.email)) {
      setError("This is not a valid email");
      setLoading(false);
      return;
    }
    CustomerService.updateCustomer(editCustomer)
      .then((resp) => {
        setLoading(false);

        toggleEditCModal({});
        getCustomers("", 20, 1);
      })
      .catch((err) => {
        setError(err.response.data);
        setLoading(false);
      });
  };

  const getCustomers = (query, perPage, page) => {
    setListLoading(true);
    setListError(false);
    CustomerService.getCustomers("", perPage, page)
      .then((resp) => {
        setCustomerList(resp.customers);
        setcustomerCount(resp.count);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
      });
  };
  useEffect(() => {
    getCustomers(
      searchParams.get("filter") || "",
      20,
      searchParams.get("page")
    );
  }, []);
  return (
    <div className="">
      <div className="flex space-x-4 my-4">
        <h1 className="text-2xl mb-4 flex-1">Customers</h1>
        {delCount > 0 && (
          <button className="flex space-x-2 text-red-600 hover:text-gray-800 py-2 px-3 rounded focus:outline-none focus:shadow-outline text-sm items-center">
            <TrashIcon className="h-4 w-4" />
            <p>Delete {delCount} selected</p>
          </button>
        )}
        <button
          className="flex space-x-2 items-center bg-black hover:bg-gray-800 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
          onClick={toggleNewCModal}
        >
          <PlusIcon className="h-4 w-4" />
          <p>New Customer</p>
        </button>
      </div>
      <table className="table-auto w-full mb-3 border border-gray-200">
        <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
          <tr>
            <th></th>
            <th className="p-2">
              <div className="font-semibold text-left">Name</div>
            </th>
            <th className="p-2">
              <div className="font-semibold text-left">Phone Number</div>
            </th>
            <th className="p-2 max-md:hidden">
              <div className="font-semibold text-left">Email Address</div>
            </th>
            <th className="p-2">
              <div className="font-semibold text-left">Orders</div>
            </th>
            <th className="p-2">
              <div className="font-semibold text-center">Action</div>
            </th>
          </tr>
        </thead>

        <tbody className="text-sm divide-y divide-gray-100">
          {listLoading ? (
            <tr>
              <td colSpan={5} className="">
                <ArrowPathIcon className="h-6 w-6 mx-auto animate-spin" />
              </td>
            </tr>
          ) : listError ? (
            <tr>
              <td colSpan={5} className="">
                <p className="text-center">{listError}</p>
              </td>
            </tr>
          ) : (
            customerList.map((customer, index) => (
              <tr key={index}>
                <td className="p-2">
                  <input
                    type="checkbox"
                    className="w-5 h-5 cursor-pointer"
                    onChange={() => handleSelectCustomer(index)}
                    value="id-1"
                  />
                </td>
                <td className="p-2">
                  <div className="font-medium text-gray-800">
                    <Link to={"/dashboard/orders?customerId=" + customer.id}>
                      {customer.firstName} {customer.lastName}
                    </Link>
                  </div>
                </td>
                <td className="p-2">
                  <div className="text-left">{customer.phone}</div>
                </td>
                <td className="p-2 max-md:hidden">
                  <div className="text-left">{customer.email}</div>
                </td>
                <td className="p-2">
                  <div className="text-left">
                    {customer.ordersCount === 1
                      ? "1 order"
                      : customer.ordersCount + " orders"}
                  </div>
                </td>
                <td className="p-2">
                  <div className="flex space-x-1 justify-center">
                    <button
                      onClick={() => toggleEditCModal(customer)}
                      className="flex space-x-2"
                    >
                      <PencilIcon className="h-4 w-4" />
                      <p>Edit</p>
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className="flex  items-center">
        <button
          onClick={() => navigatePages(!page ? 2 : parseInt(page) - 1, filter)}
          disabled={!page || parseInt(page) === 1}
          className="bg-white border border-gray-300 p-1 hover:bg-gray-100 disabled:bg-gray-100"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <button
          onClick={() => navigatePages(!page ? 2 : parseInt(page) + 1, filter)}
          disabled={page * 20 >= customerCount}
          className="bg-white border border-gray-300 p-1 hover:bg-gray-100 disabled:bg-gray-100"
        >
          <ChevronRightIcon className="h-6 w-6" />
        </button>
        <small className="text-right flex-1">
          Page {page}/{Math.ceil(customerCount / 20)} - {customerCount} results
        </small>
      </div>
      <small className="text-right">
        Showing {customerList.length} results
      </small>
      <AddCustomerModal
        open={openNewCModal}
        close={() => setOpenNewCModal(false)}
        getCustomers={() => getCustomers("", 20, 1)}
      />
      <div
        className={`py-12 bg-gray-700 bg-opacity-40 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0 ${
          !openEditCModal && "hidden"
        }`}
        id="modal"
      >
        <div
          role="alert"
          className={`container mx-auto w-11/12 md:w-2/3 max-w-lg`}
        >
          <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            <div className="text-gray-800 text-2xl font-bold mb-4">
              Edit Customer
            </div>
            {error && (
              <div
                className="flex items-center space-x-2 mb-3 bg-red-500 bg-opacity-80 text-white text-sm font-bold px-4 py-3"
                role="alert"
              >
                <InformationCircleIcon className="h-4 w-4" />
                <p>{error}</p>
              </div>
            )}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label
                  htmlFor="firstName"
                  className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                >
                  Firstname
                </label>
                <input
                  name="firstName"
                  onChange={(e) => handleEditChange(e)}
                  value={editCustomer.firstName || ""}
                  className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder="James"
                />
              </div>
              <div className="flex-1">
                <label
                  htmlFor="lastName"
                  className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                >
                  Lastname (optional)
                </label>
                <input
                  name="lastName"
                  onChange={(e) => handleEditChange(e)}
                  value={editCustomer.lastName || ""}
                  className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder="Nwafor"
                />
              </div>
            </div>
            <label
              htmlFor="phone"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Phone Number
            </label>
            <div className="relative mb-5 mt-2">
              <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                +234
              </div>
              <input
                name="phone"
                onChange={(e) => handleEditChange(e)}
                value={editCustomer.phone || ""}
                className="text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-20 text-sm border-gray-300 rounded border"
                placeholder="XXXXXXXXXXX"
              />
            </div>
            <label
              htmlFor="email"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Email
            </label>
            <input
              name="email"
              onChange={(e) => handleEditChange(e)}
              value={editCustomer.email || ""}
              className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
              placeholder="somebody@service.com"
            />
            <label
              htmlFor="dob"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Date Of Birth (optional)
            </label>
            <div className="relative mb-5 mt-2">
              <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                <CalendarDaysIcon className="h-5 w-5" />
              </div>
              <input
                name="dob"
                onChange={(e) => handleEditChange(e)}
                value={editCustomer.dob || ""}
                className="text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="DD/MM/YYYY"
              />
            </div>

            <div className="flex items-center justify-start w-full">
              <button
                onClick={handleEditCustomer}
                disabled={loading}
                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition duration-150 ease-in-out hover:bg-gray-800 bg-black rounded text-white px-8 py-2 text-sm"
              >
                {loading ? (
                  <ArrowPathIcon className="h-6 w-6 mx-2 animate-spin" />
                ) : (
                  "Submit"
                )}
              </button>
              <button
                className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                onClick={toggleEditCModal}
              >
                Cancel
              </button>
            </div>
            <button
              onClick={toggleEditCModal}
              className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Customers;
