import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router-dom";
const BackButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // 👇️ replace set to true
    navigate(-1);
  };
  return (
    <button onClick={handleClick} className="border border-gray-600 p-2">
      <ChevronLeftIcon className="h-6 w-6" />
    </button>
  );
};
export default BackButton;
