import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleSubmit = () => {
    console.log("logged in", auth.isLoggedIn);
    setLoading(true);
    if (validator.isEmpty(email)) {
      setEmailError("Login cannot be empty");
      setLoading(false);
      return;
    }
    if (validator.isEmpty(password)) {
      setPasswordError("Password cannot be empty");
      setLoading(false);
      return;
    }
    dispatch(login(email, password))
      .then(() => {
        console.log("Logged In", auth.isLoggedIn);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  if (auth.isLoggedIn) {
    return <Navigate to="/dashboard/home" replace={true} />;
  }
  return (
    <div>
      <nav className="flex items-center justify-between flex-wrap p-6 border-b border-gray-100">
        <div className="flex items-center flex-shrink-0 text-black mr-6">
          <span className="font-semibold text-2xl tracking-tight">Care</span>
        </div>
      </nav>
      <div className="mt-9">
        <h1 className="text-3xl text-center">Log in to Care</h1>

        <div className="flex justify-center">
          <div className="w-full max-w-lg">
            <form className="px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Login
                </label>
                <input
                  className={`shadow appearance-none border ${
                    emailError && "border-red-500"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="email"
                  type="text"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError();
                  }}
                  placeholder="Email"
                />
                {emailError && (
                  <p className="text-red-500 text-xs mt-3 italic">
                    {emailError}
                  </p>
                )}
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className={`shadow appearance-none border ${
                    passwordError && "border-red-500"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="password"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError();
                  }}
                  placeholder="******************"
                />
                {passwordError && (
                  <p className="text-red-500 text-xs mt-3 italic">
                    {passwordError}
                  </p>
                )}
              </div>
              <div className="flex items-center justify-between">
                <button
                  onClick={handleSubmit}
                  disabled={loading}
                  className="disabled:opacity-75 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                >
                  {loading ? (
                    <ArrowPathIcon className="h-6 w-6 mx-2 animate-spin" />
                  ) : (
                    "Log In"
                  )}
                </button>
              </div>
            </form>
            <p className="text-center text-gray-500 text-xs">
              &copy; 2022 Care by Sneaklin. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
