import React, { forwardRef, useState, useEffect } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowPathIcon,
  PencilIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/solid";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddressPicker from "../../components/AddressPicker";
import PickupService from "../../services/pickup.service";
import BackButton from "../../components/BackButton";

function ViewPickup() {
  let { requestId } = useParams();
  const [requestData, setRequestData] = useState({
    createdAt: new Date(),
    preferredDate: new Date(),
  });
  const [loading, setLoading] = useState(false);

  const getRequest = () => {
    setLoading(true);
    PickupService.getRequest(requestId)
      .then((resp) => {
        setRequestData(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getRequest();
  }, []);
  return (
    <div className="">
      <div className="my-4 flex items-start	space-x-4 flex-wrap">
        <BackButton />
        <div className="flex-1">
          <h1 className="text-2xl">Request #{requestData.number}</h1>
          <small className="text-gray-600">
            {format(new Date(requestData.createdAt), "dd MMMM yyyy 'at' HH:mm")}
          </small>
        </div>
        <div className="flex gap-2">
          {requestData.status !== "collected" &&
            requestData.status !== "cancelled" && (
              <Link
                to={"/dashboard/pickup/" + requestId + "/edit"}
                className="hover:bg-gray-100 px-3 py-2 flex items-center space-x-1 border"
              >
                <PencilIcon className="h-4 w-4" /> <span>Edit</span>
              </Link>
            )}
          {requestData.status === "collected" && (
            <Link
              to={"/dashboard/order/create/" + requestData.id}
              className="hover:bg-gray-100 px-3 py-2 flex items-center space-x-1 border"
            >
              <ShoppingCartIcon className="h-4 w-4" /> <span>Create Order</span>
            </Link>
          )}
        </div>
      </div>
      <div className="flex flex-col xl:flex-row w-full lg:w-3/4 2xl:w-2/3 mx-auto gap-2 justify-center">
        {loading ? (
          <ArrowPathIcon className="h-5 w-5 animate-spin" />
        ) : (
          requestData.customer && (
            <div className="w-full xl:w-2/3 flex flex-col space-y-2">
              <div className="border border-gray-300 p-5">
                <div className="">
                  <div className="flex justify-between">
                    <h1 className="text-lg font-medium">
                      {requestData.customer.firstName}{" "}
                      {requestData.customer.lastName}
                    </h1>
                  </div>
                  <p>{requestData.customer.phone}</p>
                </div>
                <div className="flex space-x-2">
                  <div className="w-full flex flex-col gap-2">
                    <p className="mt-2 font-bold">Pickup Details</p>
                    <div>
                      <p className="font-bold">
                        {requestData.address.firstName}{" "}
                        {requestData.address.lastName}
                      </p>
                      <p>
                        {requestData.address.address1},{" "}
                        {requestData.address.address2}
                      </p>
                      <p>{requestData.address.city}</p>
                      <p>+{requestData.address.phone}</p>
                    </div>
                    <div className="grid grid-cols-2 justify-between">
                      <div>
                        <p className="mb-2 font-bold">Date</p>
                        <p className="mb-2">
                          {format(
                            new Date(requestData.preferredDate),
                            "dd MMMM yyyy"
                          )}
                        </p>
                      </div>
                      <div>
                        <p className="mb-2 font-bold"># of Pairs</p>
                        <p className="mb-2">{requestData.pairsEstimate}</p>
                      </div>
                      <div>
                        <p className="mb-2 font-bold">
                          Preferred Pickup Method
                        </p>
                        <p className="mb-2 capitalize">
                          {requestData.logisticsOption}
                        </p>
                      </div>
                      <div>
                        <p className="mb-2 font-bold">Pickup Fee</p>
                        <p className="mb-2 capitalize">
                          &#8358;{requestData.pickupFee?.toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
export default ViewPickup;
