import {
  ArrowPathIcon,
  CalendarDaysIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import validator from "validator";
import customerService from "../services/customer.service";
import PickupService from "../services/pickup.service";

const AddZoneModal = (props) => {
  const { close, editZone, getZones } = props;
  const [form, setForm] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleAddZone = () => {
    setLoading(true);
    setError();

    let request = {
      name: form.name,
      locations: form.locations.split("\n"),
      pricing: { bike: form.bike, van: form.van },
    };

    console.log(request);
    if (editZone) {
      request.id = editZone.id;
      PickupService.updateZone(request)
        .then((resp) => {
          setForm({});
          setLoading(false);
          getZones();
          close();
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    } else {
      PickupService.createZone(request)
        .then((resp) => {
          setForm({});
          setLoading(false);
          getZones();
          close();
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (editZone) {
      setForm({
        name: editZone.name,
        locations: editZone.locations.join("\n"),
        bike: editZone.pricing.bike,
        van: editZone.pricing.van,
      });
    }
  }, []);

  return (
    <div
      id="modal"
      className={`py-12 bg-gray-700 bg-opacity-40 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0`}
    >
      <div
        role="alert"
        className={`container mx-auto w-11/12 md:w-2/3 max-w-lg`}
      >
        <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
          <div className="text-gray-800 text-2xl font-bold mb-3">
            {editZone ? "Edit" : "New"} Zone
          </div>
          <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">
            Enter Zone Details
          </h1>
          {error && (
            <div
              className="flex items-center space-x-2 mb-3 bg-red-500 bg-opacity-80 text-white text-sm font-bold px-4 py-3"
              role="alert"
            >
              <InformationCircleIcon className="h-4 w-4" />
              <p>{error}</p>
            </div>
          )}
          <label
            htmlFor="phone"
            className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
          >
            Name
          </label>
          <input
            name="name"
            onChange={(e) => handleChange(e)}
            defaultValue={form.name}
            className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
            placeholder="Mainland 1"
          />
          <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
            Locations (separate by line)
          </label>
          <textarea
            name="locations"
            onChange={(e) => handleChange(e)}
            defaultValue={form.locations}
            className="mb-5 mt-2 text-gray-600 h-20 focus:outline-none focus:border focus:border-gray-700 font-normal w-full flex items-center pl-3 text-sm border-gray-300 rounded border"
          />

          <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
            Bike Price
          </label>
          <input
            name="bike"
            onChange={(e) => handleChange(e)}
            defaultValue={form.bike}
            className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
          />
          <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
            Mini-Van Price
          </label>
          <input
            name="van"
            onChange={(e) => handleChange(e)}
            defaultValue={form.van}
            className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
          />
          <div className="flex items-center justify-start w-full">
            <button
              onClick={handleAddZone}
              disabled={loading}
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition duration-150 ease-in-out hover:bg-gray-800 bg-black rounded text-white px-8 py-2 text-sm"
            >
              {loading ? (
                <ArrowPathIcon className="h-6 w-6 mx-2 animate-spin" />
              ) : (
                "Submit"
              )}
            </button>
            <button
              className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
              onClick={close}
            >
              Cancel
            </button>
          </div>
          <button
            onClick={close}
            className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddZoneModal;
