import React from "react";
const OrderTotalCalculator = (props) => {
  const { subTotal, discount, tax, returnFee, dropFee } = props;
  return (
    <p>
      &#8358;
      {(
        subTotal -
        (subTotal * discount) / 100 +
        ((subTotal - (subTotal * discount) / 100) * tax) / 100 +
        dropFee +
        returnFee
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </p>
  );
};
export default OrderTotalCalculator;
