import userAPI from "./index.service";

class ReportService {
  getOverview(req) {
    return userAPI.get("overview", { params: req }).then((response) => {
      return response.data;
    });
  }
  getServiceOverview(req) {
    return userAPI
      .get("overview/services", { params: req })
      .then((response) => {
        return response.data;
      });
  }
}

export default new ReportService();
