import {
  ArrowPathIcon,
  BellAlertIcon,
  CheckIcon,
  ChevronDownIcon,
  CreditCardIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import OrderService from "../services/order.service";
import ConfirmActionModalComponent from "./ConfirmActionModal";

export default function DueOrderNotification(props) {
  const { order, orders, setOrders } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [dueOrders, setDueOrders] = useState([]);
  const dropDownRef = useRef(null);

  const getDueOrders = () => {
    OrderService.getDueOrders()
      .then((response) => {
        setDueOrders(response.orders);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleClickOutsideDropDown = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropDown, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropDown, true);
    };
  }, []);
  useEffect(() => {
    getDueOrders();
  }, []);
  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="h-9 w-9 rounded-full border border-red-500 bg-red-500"
      >
        <span className="text-sm text-white p-1.5 rounded-full">
          {dueOrders.length}
        </span>
      </button>
      <div
        ref={dropDownRef}
        className={`${
          isOpen ? "" : "hidden"
        } absolute right-0 w-72 bg-white rounded-lg shadow-md z-10 mt-2`}
      >
        <p className="font-medium py-2 px-4 bg-gray-100">Due Orders</p>
        <ul className="overflow-auto h-96">
          {dueOrders.map((due, index) => {
            return (
              <li
                key={index}
                className="text-gray-700 text-base py-2 px-4 hover:bg-gray-100"
              >
                <span className="font-bold">
                  {due.customer.firstName} {due.customer.lastName}
                </span>
                's order <span className="font-bold">#{due.number}</span> is due{" "}
                {due.return.date > new Date()
                  ? moment(due.return.date).toNow()
                  : moment(due.return.date).fromNow()}
                .
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
