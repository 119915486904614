import React, { useState } from "react";
import {
  Bars3Icon,
  UserIcon,
  LockClosedIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Outlet, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import "./Dashboard.css";
import { logout } from "../../actions/auth";

import classNames from "classnames";
import toast, { Toaster } from "react-hot-toast";
import styles from "./Dashboard.module.css";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";
import orderService from "../../services/order.service";
import DueOrderNotification from "../../components/DueOrderNotification";

function Dashboard() {
  const [isAsideOpen, setAsideOpen] = useState(false);
  const [isHMenuOpen, setHMenuOpen] = useState(false);
  const navigate = useNavigate();
  let page = useLocation();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const user = JSON.parse(localStorage.getItem("user"));

  const toggleAside = () => {
    setAsideOpen(!isAsideOpen);
  };

  const toggleHeaderMenu = () => {
    setHMenuOpen(!isHMenuOpen);
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate("/auth/login");
  };
  if (!auth.isLoggedIn) {
    return <Navigate to="/auth/login" replace={true} />;
  }

  const notify = (type, header, message) => {
    toast.custom(
      (t) => (
        <div
          className={classNames([
            styles.notificationWrapper,
            t.visible ? "bottom-0" : "-bottom-96",
            type === "error" ? "bg-red-600" : "bg-green-600",
          ])}
        >
          <div className={styles.iconWrapper}>
            {type === "error" && <XMarkIcon className="h-6 w-6" />}
            {type === "success" && <CheckBadgeIcon className="h-6 w-6" />}
          </div>
          <div className={styles.contentWrapper}>
            <h1>{header}</h1>
            <p className="text-left">{message}</p>
          </div>
          <div className={styles.closeIcon} onClick={() => toast.dismiss(t.id)}>
            <XCircleIcon className="h-6 w-6" />
          </div>
        </div>
      ),
      { id: "unique-notification", position: "bottom-center" }
    );
  };

  return (
    <main className="min-h-screen w-full">
      <header className="flex w-full items-center justify-between border-b border-gray-100 bg-white p-2">
        <div className="flex items-center space-x-2">
          <button
            type="button"
            className="text-3xl lg:hidden"
            onClick={toggleAside}
          >
            <i className="bx bx-menu"></i>
            <Bars3Icon className="h-6 w-6 text-black" />
          </button>
          <div className="text-black mr-6">
            <span className="font-semibold text-2xl tracking-tight">Care</span>
          </div>
        </div>

        <div className="flex space-x-2">
          <Link
            to={"dashboard/order/create"}
            className="flex items-center px-3 rounded-md bg-black text-white"
          >
            Create Order
          </Link>
          <div>
            <Toaster />
          </div>
          <DueOrderNotification />
          <button
            type="button"
            onClick={toggleHeaderMenu}
            className="h-9 w-9 overflow-hidden rounded-full bg-gray-800"
          >
            <UserIcon className="h-5 w-5 text-white mx-auto" />
          </button>

          <div
            className={`${
              isHMenuOpen ? "" : "hidden"
            } absolute right-2 mt-1 w-48 divide-y divide-gray-200 rounded-md border border-gray-200 bg-white shadow-md`}
          >
            {/* <div className="flex items-center space-x-2 p-2">
              <div className="font-medium">'Tife Pariola</div>
            </div> */}

            <div className="p-2">
              <button
                onClick={handleLogout}
                className="flex justify-items-end w-full space-x-2 transition hover:text-blue-600"
              >
                <LockClosedIcon className="h-6 w-6" />
                <div>Logout</div>
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className="flex screen">
        <aside
          className={`flex w-72 flex-col space-y-2 bg-gray-100 p-2 ${
            isAsideOpen ? "" : "max-lg:hidden"
          }`}
        >
          <Link
            to="/dashboard/home"
            className={`flex items-center space-x-1 rounded-md px-2 py-3 hover:bg-black hover:text-white ${
              page.pathname === "/dashboard/home" && "bg-black text-white"
            }`}
            onClick={toggleAside}
          >
            <span className="text-2xl">
              <i className="bx bx-home"></i>
            </span>
            <span>Home</span>
          </Link>
          <Link
            to="/dashboard/customers"
            className={`flex items-center space-x-1 rounded-md px-2 py-3 hover:bg-black hover:text-white ${
              page.pathname === "/dashboard/customers" && "bg-black text-white"
            }`}
            onClick={toggleAside}
          >
            <span className="text-2xl">
              <i className="bx bx-home"></i>
            </span>
            <span>Customers</span>
          </Link>
          <Link
            to="/dashboard/orders"
            className={`flex items-center space-x-1 rounded-md px-2 py-3 hover:bg-black hover:text-white ${
              page.pathname.includes("order") && "bg-black text-white"
            }`}
            onClick={toggleAside}
          >
            <span className="text-2xl">
              <i className="bx bx-home"></i>
            </span>
            <span>Orders</span>
          </Link>
          <Link
            to="/dashboard/pickups"
            className={`flex items-center space-x-1 rounded-md px-2 py-3 hover:bg-black hover:text-white ${
              page.pathname.includes("pickup") && "bg-black text-white"
            }`}
            onClick={toggleAside}
          >
            <span className="text-2xl">
              <i className="bx bx-home"></i>
            </span>
            <span>Pickup Requests</span>
          </Link>
          <Link
            to="/dashboard/logistics"
            className={`flex items-center space-x-1 rounded-md px-2 py-3 hover:bg-black hover:text-white ${
              page.pathname.includes("logistics") && "bg-black text-white"
            }`}
            onClick={toggleAside}
          >
            <span className="text-2xl">
              <i className="bx bx-home"></i>
            </span>
            <span>Logistics</span>
          </Link>
          {user.role === "admin" && (
            <Link
              to="/dashboard/users"
              className={`flex items-center space-x-1 rounded-md px-2 py-3 hover:bg-black hover:text-white ${
                page.pathname.includes("users") && "bg-black text-white"
              }`}
              onClick={toggleAside}
            >
              <span className="text-2xl">
                <i className="bx bx-home"></i>
              </span>
              <span>Manage Users</span>
            </Link>
          )}
        </aside>

        <div className="w-full p-4">
          <Outlet context={notify} />
        </div>
      </div>
    </main>
  );
}
export default Dashboard;
