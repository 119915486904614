import React, { forwardRef, useState, useEffect, useRef } from "react";
import {
  TrashIcon,
  UserCircleIcon,
  PlusIcon,
  ShoppingCartIcon,
  MinusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  CalendarDaysIcon,
  XMarkIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import validator from "validator";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import OrderService from "../../services/order.service";
import OrderTotalCalculator from "../../components/OrderTotalCalculator";
import BackButton from "../../components/BackButton";
import AddressPicker from "../../components/AddressPicker";

function EditOrder() {
  let { order } = useParams();
  const [dropFee, setDropFee] = useState(0);
  const [returnFee, setReturnFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [collectionData, setCollectionData] = useState({
    dropType: "walk-in",
    returnType: "walk-in",
    dropDate: new Date(),
    returnDate: new Date(Date.now() + 3600 * 1000 * 72),
  });
  const [servicesList, setServicesList] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [service, setService] = useState("");
  const [customer, setCustomer] = useState();
  const [orderDetails, setOrderDetails] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [addressModal, setAddressModal] = useState("");
  const [selectedAddresses, setSelectedAddresses] = useState({});

  const navigate = useNavigate();

  const ref = useRef(null);

  const [pay, setPay] = useState("");

  const handleUpdateOrder = (confirm) => {
    setLoading(true);
    let servicePayload = {};
    orderList.map(
      (order) =>
        (servicePayload = { ...servicePayload, [order.code]: order.qty })
    );
    const request = {
      drop: {
        fee: dropFee * 100,
        type: collectionData.dropType, // delivery here means we go pick it up
        date: collectionData.dropDate.toISOString().split("T")[0], // ISO 8601 format : YYYY-MM-DD
        address: selectedAddresses.drop,
      },
      return: {
        fee: returnFee * 100,
        type: collectionData.returnType, // delivery here means we go pick it up
        date: collectionData.returnDate.toISOString().split("T")[0], // ISO 8601 format : YYYY-MM-DD
        address: selectedAddresses.return,
      },
      id: order,
      customerId: customer.id,
      services: servicePayload,
      paymentMethod: !pay ? "" : pay,
      discount,
      confirm,
    };
    console.log(request);
    OrderService.updateOrder(request, confirm)
      .then((resp) => {
        setLoading(false);
        navigate("/dashboard/orders");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleClickOutsideSearch = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenSuggestions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSearch, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideSearch, true);
    };
  }, []);
  const handleAddOrder = () => {
    if (service !== "") {
      if (orderList.filter((s) => s.code === service).length > 0) {
        const nextCounters = orderList.map((c, i) => {
          if (c.code === service) {
            // Increment the clicked counter
            c.qty = c.qty + 1;
            return c;
          } else {
            // The rest haven't changed
            return c;
          }
        });
        setOrderList(nextCounters);
        return;
      }
      let selectService = servicesList.filter((s) => s.code === service);
      selectService[0].qty = 1;
      setOrderList((current) => [...current, selectService[0]]);
    }
  };
  const handleQtyChange = (index, newQty) => {
    const nextCounters = orderList.map((c, i) => {
      if (i === index && newQty > 0) {
        c.qty = newQty;
      }
      return c;
    });
    setOrderList(nextCounters);
  };
  const removeService = (index) => {
    const remainingServices = orderList.filter(
      (file) => file !== orderList[index]
    );
    setOrderList(remainingServices);
  };
  const getServices = () => {
    OrderService.getServices()
      .then((resp) => {
        setServicesList(resp.services);
      })
      .catch((err) => {});
  };
  const getOrder = () => {
    setOrderLoading(true);
    OrderService.getOrder(order)
      .then((resp) => {
        setOrderDetails(resp);
        setCustomer(resp.customer);
        let orderLis = [];
        Object.keys(resp.services).forEach(function (key) {
          orderLis.push({ code: key, qty: resp.services[key] });
        });
        setSelectedAddresses({
          drop: resp.drop.address,
          return: resp.return.address,
        });
        setOrderList(orderLis);
        setCollectionData({
          dropDate: new Date(resp.drop.date),
          returnDate: new Date(resp.return.date),
          dropType: resp.drop.type,
          returnType: resp.return.type,
        });
        setPay(resp.paymentMethod);
        setDropFee(resp.drop.fee / 100);
        setReturnFee(resp.return.fee / 100);
        setDiscount(resp.discount);
        setOrderLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOrderLoading(false);
      });
  };
  const findCustomer = (q) => {
    setOpenSuggestions(true);
    CustomerService.getCustomers(q, 10, 1)
      .then((resp) => {
        setSearchCustomers(resp.customers);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getServices();
    getOrder();
  }, []);
  return (
    <>
      {orderLoading || !orderDetails ? (
        <div className="flex items-center justify-center h-full">
          <ArrowPathIcon className="h-10 w-10 mx-auto animate-spin" />
        </div>
      ) : (
        <div className="">
          <div className="my-4 flex items-start	space-x-4 flex-wrap">
            <BackButton />
            <div className="flex-1">
              <h1 className="text-2xl">Edit Order #{orderDetails.number}</h1>
              <small className="text-gray-600">
                {format(
                  new Date(orderDetails.createdAt),
                  "dd MMMM yyyy 'at' HH:mm"
                )}
              </small>
            </div>
          </div>
          <div className="flex flex-col xl:flex-row w-full lg:w-3/4 2xl:w-2/3 mx-auto gap-2 justify-center">
            <div className="w-full xl:w-2/3 flex flex-col space-y-2">
              <div className="border border-gray-300 p-5">
                <div className="flex items-center mb-4">
                  <h1 className="text-xl flex-1">Services</h1>
                  <span
                    className={`text-xs font-semibold py-1 px-2 uppercase rounded  ${
                      orderDetails.status === "draft"
                        ? "text-gray-600 bg-gray-200"
                        : orderDetails.status === "processing"
                        ? "text-yellow-600 bg-yellow-200"
                        : "text-green-600 bg-green-200"
                    } uppercase`}
                  >
                    {orderDetails.status}
                  </span>
                </div>
                <table className="table-auto w-full border-separate border-spacing-2">
                  <tbody>
                    {orderList.map((order, index) => (
                      <tr key={index} className="py-2">
                        <td>
                          <span className="font-medium">
                            {
                              servicesList.filter(
                                (service) => service.code === order.code
                              )[0]?.name
                            }
                          </span>
                        </td>
                        {orderDetails.status === "draft" ? (
                          <td>
                            <div className="flex flex-row w-24 relative bg-transparent">
                              <button
                                onClick={() =>
                                  handleQtyChange(index, order.qty - 1)
                                }
                                className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 w-20 cursor-pointer outline-none"
                              >
                                <span className="m-auto text-2xl font-thin">
                                  <MinusIcon className="h-4 w-4 mx-auto" />
                                </span>
                              </button>
                              <input
                                type="number"
                                className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                                name="custom-input-number"
                                min={1}
                                onChange={(e) =>
                                  handleQtyChange(index, e.target.valueAsNumber)
                                }
                                value={order.qty}
                              />
                              <button
                                onClick={() =>
                                  handleQtyChange(index, order.qty + 1)
                                }
                                className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 w-20 cursor-pointer"
                              >
                                <span className="m-auto text-2xl font-thin">
                                  <PlusIcon className="h-4 w-4 mx-auto" />
                                </span>
                              </button>
                            </div>
                          </td>
                        ) : (
                          <td className="text-left">x{order.qty}</td>
                        )}
                        <td>
                          &#8358;
                          {(
                            (order.qty *
                              servicesList.filter(
                                (service) => service.code === order.code
                              )[0]?.cost) /
                            100
                          ).toLocaleString()}
                        </td>
                        {orderDetails.status === "draft" && (
                          <td className="text-right">
                            <button
                              className="text-red-600"
                              onClick={() => removeService(index)}
                            >
                              Remove
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <hr />
                <div className="flex space-x-2 mt-3">
                  <select
                    id="services"
                    value={service}
                    onChange={(e) => setService(e.target.value)}
                    className="border border-gray-300 text-gray-900 text-sm block w-full p-2.5"
                  >
                    <option value="">Choose a service</option>
                    {servicesList.map((service) => (
                      <option key={service.code} value={service.code}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                  <button
                    className="bg-black text-white p-2"
                    onClick={handleAddOrder}
                  >
                    <ShoppingCartIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="border border-gray-300 p-5">
                <div className="flex space-x-2">
                  <div className="w-full flex flex-col gap-2">
                    <h4 className="text-lg mb-2">Drop Off</h4>
                    <select
                      value={collectionData.dropType}
                      onChange={(e) =>
                        setCollectionData({
                          ...collectionData,
                          dropType: e.target.value,
                        })
                      }
                      className="border border-gray-300 text-gray-900 text-sm block w-full p-2.5"
                    >
                      <option value={"walk-in"}>Walk In</option>
                      <option value={"delivery"}>Pickup</option>
                    </select>
                    <div className="relative w-full">
                      <DatePicker
                        selected={collectionData.dropDate}
                        onChange={(date) => {
                          let endD = new Date();
                          endD.setFullYear(date.getFullYear());
                          endD.setMonth(date.getMonth());
                          endD.setDate(date.getDate() + 5);

                          setCollectionData({
                            ...collectionData,
                            dropDate: date,
                            returnDate:
                              date > collectionData.returnDate
                                ? endD
                                : collectionData.returnDate,
                          });
                        }}
                        selectsStart
                        startDate={collectionData.dropDate}
                        endDate={collectionData.returnDate}
                        minDate={new Date()}
                        nextMonthButtonLabel=">"
                        previousMonthButtonLabel="<"
                        popperClassName="react-datepicker-left"
                        customInput={<ButtonInput />}
                        renderCustomHeader={({
                          date,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className="flex items-center justify-between px-2 py-2">
                            <span className="text-lg text-gray-700">
                              {format(date, "MMMM yyyy")}
                            </span>

                            <div className="space-x-2">
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                type="button"
                                className={`
                                            ${
                                              prevMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                              >
                                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                              </button>

                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                type="button"
                                className={`
                                            ${
                                              nextMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                              >
                                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </div>

                    {collectionData.dropType === "delivery" &&
                      (selectedAddresses.drop ? (
                        <div>
                          <p className="font-bold">
                            {selectedAddresses.drop.firstName}{" "}
                            {selectedAddresses.drop.lastName}
                          </p>
                          <p>
                            {selectedAddresses.drop.address1},{" "}
                            {selectedAddresses.drop.address2}
                          </p>
                          <p>{selectedAddresses.drop.city}</p>
                          <p>+234{selectedAddresses.drop.phone}</p>
                          <button
                            className="text-sm text-red-600 hover:underline"
                            onClick={() => setAddressModal("drop")}
                          >
                            Change
                          </button>
                        </div>
                      ) : (
                        <div>
                          <p>No default address</p>
                          <button
                            className="text-sm text-red-600 hover:underline"
                            onClick={() => setAddressModal("drop")}
                          >
                            Select
                          </button>
                        </div>
                      ))}
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <h4 className="text-lg mb-2">Return</h4>
                    <select
                      value={collectionData.returnType}
                      onChange={(e) =>
                        setCollectionData({
                          ...collectionData,
                          returnType: e.target.value,
                        })
                      }
                      className="border border-gray-300 text-gray-900 text-sm block w-full p-2.5"
                    >
                      <option value={"walk-in"}>Walk In</option>
                      <option value={"delivery"}>Delivery</option>
                    </select>

                    <div className="relative w-full">
                      <DatePicker
                        selected={collectionData.returnDate}
                        onChange={(date) => {
                          let startD = new Date();
                          startD.setFullYear(date.getFullYear());
                          startD.setMonth(date.getMonth());
                          startD.setDate(date.getDate() - 5);

                          setCollectionData({
                            ...collectionData,
                            returnDate: date,
                            dropDate:
                              date < collectionData.dropDate
                                ? startD
                                : collectionData.dropDate,
                          });
                        }}
                        selectsEnd
                        startDate={collectionData.dropDate}
                        endDate={collectionData.returnDate}
                        minDate={new Date()}
                        nextMonthButtonLabel=">"
                        previousMonthButtonLabel="<"
                        popperClassName="react-datepicker-right"
                        customInput={<ButtonInput />}
                        renderCustomHeader={({
                          date,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className="flex items-center justify-between px-2 py-2">
                            <span className="text-lg text-gray-700">
                              {format(date, "MMMM yyyy")}
                            </span>

                            <div className="space-x-2">
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                type="button"
                                className={`
                                            ${
                                              prevMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                              >
                                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                              </button>

                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                type="button"
                                className={`
                                            ${
                                              nextMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                              >
                                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </div>

                    {collectionData.returnType === "delivery" &&
                      (selectedAddresses.return ? (
                        <div>
                          <p className="font-bold">
                            {selectedAddresses.return.firstName}{" "}
                            {selectedAddresses.return.lastName}
                          </p>
                          <p>
                            {selectedAddresses.return.address1},{" "}
                            {selectedAddresses.return.address2}
                          </p>
                          <p>{selectedAddresses.return.city}</p>
                          <p>+234{selectedAddresses.return.phone}</p>
                          <button
                            className="text-sm text-red-600 hover:underline"
                            onClick={() => setAddressModal("return")}
                          >
                            Change
                          </button>
                        </div>
                      ) : (
                        <div>
                          <p>No default address</p>
                          <div className="flex gap-2">
                            <button
                              className="text-sm text-red-600 hover:underline"
                              onClick={() => setAddressModal("return")}
                            >
                              Select
                            </button>
                            <button
                              className="text-sm text-red-600 hover:underline"
                              onClick={() =>
                                setSelectedAddresses({
                                  ...selectedAddresses,
                                  return: selectedAddresses.drop,
                                })
                              }
                            >
                              Same as pickup
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {orderDetails.status === "draft" && (
                <div className="border border-gray-300 p-5">
                  <h1 className="text-xl mb-4 flex-1">Payment</h1>
                  <ul className="flex flex-wrap gap-3">
                    <li className="relative">
                      <input
                        className="sr-only peer"
                        type="radio"
                        value="cash"
                        name="answer"
                        checked={pay === "cash"}
                        id="answer_cash"
                        onChange={(e) => {
                          setPay(e.target.value);
                        }}
                      />
                      <label
                        className="flex p-5 bg-white border border-gray-300 cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-black peer-checked:ring-2 peer-checked:border-transparent"
                        htmlFor="answer_cash"
                      >
                        Cash
                      </label>

                      <div className="absolute hidden w-5 h-5 peer-checked:block top-0 right-0">
                        <CheckCircleIcon className="w-5 h-5" />
                      </div>
                    </li>
                    <li className="relative">
                      <input
                        className="sr-only peer"
                        type="radio"
                        value="card"
                        name="answer"
                        checked={pay === "card"}
                        id="answer_card"
                        onChange={(e) => {
                          setPay(e.target.value);
                        }}
                      />
                      <label
                        className="flex p-5 bg-white border border-gray-300 cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-black peer-checked:ring-2 peer-checked:border-transparent"
                        htmlFor="answer_card"
                      >
                        Card
                      </label>

                      <div className="absolute hidden w-5 h-5 peer-checked:block top-0 right-0">
                        <CheckCircleIcon className="w-5 h-5" />
                      </div>
                    </li>

                    <li className="relative">
                      <input
                        className="sr-only peer"
                        type="radio"
                        value="transfer"
                        name="answer"
                        id="answer_transfer"
                        checked={pay === "transfer"}
                        onChange={(e) => {
                          setPay(e.target.value);
                        }}
                      />
                      <label
                        className="flex p-5 bg-white border border-gray-300 cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-black peer-checked:ring-2 peer-checked:border-transparent"
                        htmlFor="answer_transfer"
                      >
                        Transfer
                      </label>

                      <div className="absolute hidden w-5 h-5 peer-checked:block top-0 right-0">
                        <CheckCircleIcon className="w-5 h-5" />
                      </div>
                    </li>
                    <li className="relative">
                      <input
                        className="sr-only peer"
                        type="radio"
                        value=""
                        name="answer"
                        id="answer_later"
                        checked={!pay}
                        onChange={(e) => {
                          setPay(e.target.value);
                        }}
                      />
                      <label
                        className="flex p-5 bg-white border border-gray-300 cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-black peer-checked:ring-2 peer-checked:border-transparent"
                        htmlFor="answer_later"
                      >
                        Later
                      </label>

                      <div className="absolute hidden w-5 h-5 peer-checked:block top-0 right-0">
                        <CheckCircleIcon className="w-5 h-5" />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="w-full xl:w-1/3 flex flex-col space-y-2">
              <div className="border border-gray-300 p-5">
                {!customer ? (
                  <div className="flex space-x-2">
                    <div className="relative flex-1">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">
                          <UserCircleIcon className="h-4 w-4" />
                        </span>
                      </div>
                      <input
                        placeholder="Find Customer"
                        value={searchQuery}
                        className="w-full border border-gray-300 p-2 pl-8"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          findCustomer(e.target.value);
                        }}
                      />
                      {openSuggestions && (
                        <div className="absolute bg-white w-full shadow-md mt-2 max-h-52 overflow-auto">
                          <ul className="" ref={ref}>
                            {searchCustomers.map((customer, index) => (
                              <li
                                key={index}
                                onClick={(e) => {
                                  setCustomer(customer);
                                  setOpenSuggestions(false);
                                }}
                                className="flex flex-col hover:bg-gray-100 px-4 py-3"
                              >
                                <p>
                                  {customer.firstName} {customer.lastName}
                                </p>
                                <small className="text-gray-600">
                                  {customer.email}
                                </small>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <button className="bg-black text-white p-2">
                      <PlusIcon className="h-5 w-5" />
                    </button>
                  </div>
                ) : (
                  <div className="">
                    <div className="flex justify-between">
                      <h1 className="text-lg font-medium">
                        {customer.firstName} {customer.lastName}
                      </h1>
                      {orderDetails.status === "draft" && (
                        <button onClick={() => setCustomer()}>
                          <XMarkIcon className="h-4 w-4" />
                        </button>
                      )}
                    </div>
                    <p>{customer.phone}</p>
                    <small>{customer.email}</small>
                  </div>
                )}
              </div>

              <div className="border border-gray-300 p-5">
                <h1 className="text-xl mb-4 flex-1">Order Summary</h1>
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td className="text-right">
                        &#8358;
                        {orderList
                          .reduce((accumulator, object) => {
                            return (
                              accumulator +
                              (servicesList.filter(
                                (service) => service.code === object.code
                              )[0]?.cost *
                                object.qty) /
                                100
                            );
                          }, 0)
                          .toLocaleString()}
                      </td>
                    </tr>
                    {collectionData.dropType === "delivery" && (
                      <tr>
                        <td>Pickup</td>
                        <td className="text-right">
                          <input
                            type="number"
                            value={dropFee}
                            onChange={(e) => setDropFee(e.target.valueAsNumber)}
                            className="text-right border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </td>
                      </tr>
                    )}
                    {collectionData.returnType === "delivery" && (
                      <tr>
                        <td>Delivery</td>
                        <td className="text-right">
                          <input
                            type="number"
                            value={returnFee}
                            onChange={(e) =>
                              setReturnFee(e.target.valueAsNumber)
                            }
                            className="text-right border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="&#8358;0.00"
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Discount</td>
                      <td className="text-right">{discount}</td>
                    </tr>
                    <tr>
                      <td>Tax {tax}%</td>
                      <td className="text-right">
                        &#8358;
                        {(
                          (tax *
                            orderList.reduce((accumulator, object) => {
                              return (
                                accumulator +
                                (servicesList.filter(
                                  (service) => service.code === object.code
                                )[0]?.cost *
                                  object.qty) /
                                  100
                              );
                            }, 0)) /
                          100
                        ).toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-bold">Total</td>
                      <td className="text-right font-bold">
                        <OrderTotalCalculator
                          subTotal={orderList.reduce((accumulator, object) => {
                            return (
                              accumulator +
                              (servicesList.filter(
                                (service) => service.code === object.code
                              )[0]?.cost *
                                object.qty) /
                                100
                            );
                          }, 0)}
                          returnFee={returnFee}
                          dropFee={dropFee}
                          tax={tax}
                          discount={discount}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <button
                onClick={() => handleUpdateOrder(false)}
                disabled={!customer || orderList.length < 1 || loading}
                className="border-black border w-full text-black p-2 mt-2 text-center disabled:opacity-75"
              >
                {loading ? (
                  <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
                ) : (
                  "Save"
                )}
              </button>
              {orderDetails.status === "draft" && (
                <button
                  onClick={() => handleUpdateOrder(true)}
                  disabled={!customer || orderList.length < 1 || loading}
                  className="bg-black w-full text-white p-2 mt-2 text-center disabled:opacity-75"
                >
                  {loading ? (
                    <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
                  ) : (
                    "Save & Confirm"
                  )}
                </button>
              )}
            </div>
            {addressModal !== "" && (
              <AddressPicker
                type={addressModal}
                customer={customer}
                selectedAddresses={selectedAddresses}
                setSelectedAddresses={setSelectedAddresses}
                close={() => setAddressModal("")}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
const ButtonInput = forwardRef(({ value, onClick }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
  >
    {format(new Date(value), "dd MMMM yyyy")}
  </button>
));
export default EditOrder;
