import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrderService from "../../services/order.service";
import reportService from "../../services/report.service";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";

function Home() {
  var today = new Date();
  const [stats, setStats] = useState();
  const [prevStats, setPrevStats] = useState();
  const [servicesStats, setservicesStats] = useState([]);
  const [prevServicesStats, setPrevServicesStats] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [lastP, setLastPeriod] = useState();
  const user = JSON.parse(localStorage.getItem("user"));

  const getServiceDetail = (code) => {
    let service = servicesList.filter((f) => f.code === code);
    return service;
  };
  const getServices = () => {
    OrderService.getServices()
      .then((resp) => {
        setServicesList(resp.services);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    let req = {
      start: moment(dateRange.startDate).format("YYYY-MM-DD"),
      end: moment(dateRange.endDate).format("YYYY-MM-DD"),
    };
    let lastPeriod = getLastPeriod(req.start + " - " + req.end);
    setLastPeriod(lastPeriod);
    reportService
      .getOverview(req)
      .then((response) => {
        setStats(response.overview);
      })
      .catch((e) => {
        console.log(e);
      });
    reportService
      .getOverview({
        start: lastPeriod.startDate.format("YYYY-MM-DD"),
        end: lastPeriod.endDate.format("YYYY-MM-DD"),
      })
      .then((response) => {
        setPrevStats(response.overview);
      })
      .catch((e) => {
        console.log(e);
      });

    reportService
      .getServiceOverview(req)
      .then((response) => {
        setservicesStats(response.serviceOverview);
      })
      .catch((e) => {
        console.log(e);
      });
    reportService
      .getServiceOverview({
        start: lastPeriod.startDate.format("YYYY-MM-DD"),
        end: lastPeriod.endDate.format("YYYY-MM-DD"),
      })
      .then((response) => {
        setPrevServicesStats(response.serviceOverview);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [dateRange]);
  useEffect(() => {
    getServices();
  }, []);
  return (
    <div>
      <div>
        <label>Select Date Range</label>
        <div className="w-full md:w-1/3">
          <Datepicker
            inputClassName="w-full py-2 pl-2 rounded-md focus:ring-0 font-normal bg-white border text-gray-600"
            readOnly
            showShortcuts
            showFooter
            separator="to"
            configs={{
              shortcuts: {
                today: "Today",
                yesterday: "Yesterday",
                past: (period) => `Last ${period} days`,
                currentMonth: "This month",
                pastMonth: "Last month",
                thisYear: {
                  text: "This year",
                  period: {
                    start: new Date(today.getFullYear(), 0, 1),
                    end: today,
                  },
                },
              },
            }}
            maxDate={new Date()}
            value={dateRange}
            onChange={(newValue) => {
              console.log(newValue);
              setDateRange(newValue);
            }}
          />
        </div>
        {lastP && (
          <p className="text-xs text-gray-500">
            Compared to {lastP.startDate.format("YYYY-MM-DD")} -{" "}
            {lastP.endDate.format("YYYY-MM-DD")}
          </p>
        )}
      </div>
      {stats && prevStats && (
        <div className="flex flex-wrap">
          <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5 mb-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-3 xl:mb-0 shadow-lg">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                  <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                    <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                      Total Orders
                    </h5>
                    <p className="font-semibold text-xl text-blueGray-700">
                      {stats.orders.count}
                    </p>
                    <p
                      className={`${
                        calculatePercentageDifference(
                          prevStats.orders.count,
                          stats.orders.count
                        ) >= 0
                          ? "text-green-600"
                          : "text-red-600"
                      } font-semibold text-sm`}
                    >
                      {Math.round(
                        calculatePercentageDifference(
                          prevStats.orders.count,
                          stats.orders.count
                        )
                      )}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-4 xl:mb-0 shadow-lg">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                  <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                    <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                      Ready Orders
                    </h5>
                    <p className="font-semibold text-xl text-blueGray-700">
                      {stats.orders.ready}
                    </p>
                    <p
                      className={`${
                        calculatePercentageDifference(
                          prevStats.orders.ready,
                          stats.orders.ready
                        ) >= 0
                          ? "text-green-600"
                          : "text-red-600"
                      } font-semibold text-sm`}
                    >
                      {Math.round(
                        calculatePercentageDifference(
                          prevStats.orders.ready,
                          stats.orders.ready
                        )
                      )}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-4 xl:mb-0 shadow-lg">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                  <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                    <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                      Delivered Orders
                    </h5>
                    <p className="font-semibold text-xl text-blueGray-700">
                      {stats.orders.delivered}
                    </p>
                    <p
                      className={`${
                        calculatePercentageDifference(
                          prevStats.orders.delivered,
                          stats.orders.delivered
                        ) >= 0
                          ? "text-green-600"
                          : "text-red-600"
                      } font-semibold text-sm`}
                    >
                      {Math.round(
                        calculatePercentageDifference(
                          prevStats.orders.delivered,
                          stats.orders.delivered
                        )
                      )}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                  <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                    <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                      Unpaid Orders
                    </h5>
                    <p className="font-semibold text-xl text-blueGray-700">
                      {stats.orders.unpaid}
                    </p>
                    <p
                      className={`${
                        calculatePercentageDifference(
                          prevStats.orders.unpaid,
                          stats.orders.unpaid
                        ) >= 0
                          ? "text-green-600"
                          : "text-red-600"
                      } font-semibold text-sm`}
                    >
                      {Math.round(
                        calculatePercentageDifference(
                          prevStats.orders.unpaid,
                          stats.orders.unpaid
                        )
                      )}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {user.role === "admin" && (
            <>
              <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                          Total Delivery Fee
                        </h5>
                        <p className="font-semibold text-xl text-blueGray-700">
                          &#8358;
                          {(stats.orders.logistics / 100).toLocaleString()}
                        </p>
                        <p
                          className={`${
                            calculatePercentageDifference(
                              prevStats.orders.logistics,
                              stats.orders.logistics
                            ) >= 0
                              ? "text-green-600"
                              : "text-red-600"
                          } font-semibold text-sm`}
                        >
                          {Math.round(
                            calculatePercentageDifference(
                              prevStats.orders.logistics,
                              stats.orders.logistics
                            )
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                          Service Revenue
                        </h5>
                        <p className="font-semibold text-xl text-blueGray-700">
                          &#8358;
                          {(
                            (stats.orders.revenue - stats.orders.logistics) /
                            100
                          ).toLocaleString()}
                        </p>
                        <p
                          className={`${
                            calculatePercentageDifference(
                              prevStats.orders.revenue -
                                prevStats.orders.logistics,
                              stats.orders.revenue - stats.orders.logistics
                            ) >= 0
                              ? "text-green-600"
                              : "text-red-600"
                          } font-semibold text-sm`}
                        >
                          {Math.round(
                            calculatePercentageDifference(
                              prevStats.orders.revenue -
                                prevStats.orders.logistics,
                              stats.orders.revenue - stats.orders.logistics
                            )
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                          Total Revenue
                        </h5>
                        <p className="font-semibold text-xl text-blueGray-700">
                          &#8358;
                          {(stats.orders.revenue / 100).toLocaleString()}
                        </p>
                        <p
                          className={`${
                            calculatePercentageDifference(
                              prevStats.orders.revenue,
                              stats.orders.revenue
                            ) >= 0
                              ? "text-green-600"
                              : "text-red-600"
                          } font-semibold text-sm`}
                        >
                          {Math.round(
                            calculatePercentageDifference(
                              prevStats.orders.revenue,
                              stats.orders.revenue
                            )
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                          Unpaid Revenue
                        </h5>
                        <p className="font-semibold text-xl text-blueGray-700">
                          &#8358;
                          {(stats.orders.unpaidrevenue / 100).toLocaleString()}
                        </p>
                        <p
                          className={`${
                            calculatePercentageDifference(
                              prevStats.orders.unpaidrevenue,
                              stats.orders.unpaidrevenue
                            ) >= 0
                              ? "text-green-600"
                              : "text-red-600"
                          } font-semibold text-sm`}
                        >
                          {Math.round(
                            calculatePercentageDifference(
                              prevStats.orders.unpaidrevenue,
                              stats.orders.unpaidrevenue
                            )
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                          Average Pair Value
                        </h5>
                        <p className="font-semibold text-xl text-blueGray-700">
                          &#8358;
                          {parseFloat(
                            (stats.orders.revenue - stats.orders.logistics) /
                              100 /
                              servicesStats.reduce(
                                (acc, val) => acc + val.value,
                                1
                              )
                          ).toFixed(2)}
                        </p>
                        <p
                          className={`${
                            calculatePercentageDifference(
                              parseFloat(
                                (prevStats.orders.revenue -
                                  prevStats.orders.logistics) /
                                  100 /
                                  prevServicesStats.reduce(
                                    (acc, val) => acc + val.value,
                                    1
                                  )
                              ).toFixed(2),
                              parseFloat(
                                (stats.orders.revenue -
                                  stats.orders.logistics) /
                                  100 /
                                  servicesStats.reduce(
                                    (acc, val) => acc + val.value,
                                    1
                                  )
                              ).toFixed(2)
                            ) >= 0
                              ? "text-green-600"
                              : "text-red-600"
                          } font-semibold text-sm`}
                        >
                          {Math.round(
                            calculatePercentageDifference(
                              parseFloat(
                                (prevStats.orders.revenue -
                                  prevStats.orders.logistics) /
                                  100 /
                                  prevServicesStats.reduce(
                                    (acc, val) => acc + val.value,
                                    1
                                  )
                              ),
                              parseFloat(
                                (stats.orders.revenue -
                                  stats.orders.logistics) /
                                  100 /
                                  servicesStats.reduce(
                                    (acc, val) => acc + val.value,
                                    1
                                  )
                              )
                            )
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                          Average Order Value
                        </h5>
                        <p className="font-semibold text-xl text-blueGray-700">
                          &#8358;
                          {parseFloat(
                            (stats.orders.revenue - stats.orders.logistics) /
                              100 /
                              (stats.orders.count || 1)
                          ).toFixed(2)}
                        </p>

                        <p
                          className={`${
                            calculatePercentageDifference(
                              (prevStats.orders.revenue -
                                prevStats.orders.logistics) /
                                100 /
                                (prevStats.orders.count || 1),
                              (stats.orders.revenue - stats.orders.logistics) /
                                100 /
                                (stats.orders.count || 1)
                            ) >= 0
                              ? "text-green-600"
                              : "text-red-600"
                          } font-semibold text-sm`}
                        >
                          {Math.round(
                            calculatePercentageDifference(
                              (prevStats.orders.revenue -
                                prevStats.orders.logistics) /
                                100 /
                                (prevStats.orders.count || 1),
                              (stats.orders.revenue - stats.orders.logistics) /
                                100 /
                                (stats.orders.count || 1)
                            )
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                          Total Walk-ins
                        </h5>
                        <p className="font-semibold text-xl text-blueGray-700">
                          {stats.orders.dropType.walk_in}
                        </p>

                        <p
                          className={`${
                            calculatePercentageDifference(
                              prevStats.orders.dropType.walk_in,
                              stats.orders.dropType.walk_in
                            ) >= 0
                              ? "text-green-600"
                              : "text-red-600"
                          } font-semibold text-sm`}
                        >
                          {Math.round(
                            calculatePercentageDifference(
                              prevStats.orders.dropType.walk_in,
                              stats.orders.dropType.walk_in
                            )
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                  <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                      <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                          Total Delivery
                        </h5>
                        <p className="font-semibold text-xl text-blueGray-700">
                          {stats.orders.dropType.delivery}
                        </p>

                        <p
                          className={`${
                            calculatePercentageDifference(
                              prevStats.orders.dropType.delivery,
                              stats.orders.dropType.delivery
                            ) >= 0
                              ? "text-green-600"
                              : "text-red-600"
                          } font-semibold text-sm`}
                        >
                          {Math.round(
                            calculatePercentageDifference(
                              prevStats.orders.dropType.delivery,
                              stats.orders.dropType.delivery
                            )
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {user.role === "admin" && (
        <div className="mt-4 w-full lg:w-6/12 xl:w-3/12 px-5 mb-4">
          <table className="table-auto mb-3 border border-gray-200">
            <thead className={"border-b"}>
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Service</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Stats</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {servicesStats.map((service, index) => (
                <tr key={index} className={"border-b"}>
                  <td className="p-2">
                    {getServiceDetail(service.key)[0]?.name}
                  </td>
                  <td className="p-2">{service.value}</td>
                </tr>
              ))}
              <tr>
                <td className="p-2 font-bold">Total</td>
                <td className="p-2 font-bold w-4">
                  <span>
                    {servicesStats.reduce((acc, val) => acc + val.value, 0)}{" "}
                  </span>
                  <span
                    className={`${
                      calculatePercentageDifference(
                        prevServicesStats.reduce(
                          (acc, val) => acc + val.value,
                          0
                        ),
                        servicesStats.reduce((acc, val) => acc + val.value, 0)
                      ) >= 0
                        ? "text-green-600"
                        : "text-red-600"
                    } font-semibold text-xs`}
                  >
                    {Math.round(
                      calculatePercentageDifference(
                        prevServicesStats.reduce(
                          (acc, val) => acc + val.value,
                          0
                        ),
                        servicesStats.reduce((acc, val) => acc + val.value, 0)
                      )
                    )}
                    %
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className="flex flex-col items-center justify-center h-full mt-5">
        <h1 className="text-2xl mb-4 text-center">
          Welcome to Care Management System!
        </h1>
        <Link
          to="/dashboard/customers"
          className="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          View Customers
        </Link>
      </div>
    </div>
  );
}

function getLastPeriod(input) {
  let startDate, endDate;

  if (input.toLowerCase() === "today") {
    // Yesterday
    startDate = moment().subtract(1, "days");
    endDate = moment(startDate);
  } else if (input.toLowerCase() === "this week") {
    // Last week
    startDate = moment().startOf("week").subtract(1, "weeks");
    endDate = moment(startDate).endOf("week");
  } else {
    // Specific date range in format 'YYYY-MM-DD - YYYY-MM-DD'
    const dates = input.split(" - ");
    if (dates && dates.length === 2) {
      startDate = moment(dates[0], "YYYY-MM-DD");
      endDate = moment(dates[1], "YYYY-MM-DD");
      const diffDays = endDate.diff(startDate, "days");
      startDate.subtract(diffDays + 1, "days");
      endDate.subtract(diffDays + 1, "days");
    } else {
      return "Invalid date format";
    }
  }

  return { startDate, endDate };
}
function calculatePercentageDifference(original, newNumber) {
  if (original === 0) {
    return newNumber === 0 ? 0 : 100;
  }

  const difference = newNumber - original;
  const percentageChange = (difference / original) * 100;

  return percentageChange;
}
export default Home;
