import React, { useState, useEffect } from "react";
import {
  TrashIcon,
  PencilIcon,
  PlusIcon,
  ArrowPathIcon,
  ClockIcon,
  CheckIcon,
  XMarkIcon,
  BellAlertIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import { format } from "date-fns";
import validator from "validator";
import { Link } from "react-router-dom";
import OrderService from "../../services/order.service";
import OrderTotalCalculator from "../../components/OrderTotalCalculator";
import DropdownComponent from "../../components/DropdownComponent";
import ConfirmModalComponent from "../../components/ConfirmModal";
import CustomDropDown from "../../components/CustomDropDown";
import pickupService from "../../services/pickup.service";
import ConfirmPickupModalComponent from "../../components/ConfirmPickupModal";
import AddZoneModal from "../../components/AddZoneModal";
import AddUserModal from "../../components/AddUserModal";
import authService from "../../services/auth.service";

function Users() {
  const [openNewCModal, setOpenNewCModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openZoneModal, setOpenZoneModal] = useState(false);
  const [cancelRequest, setCancelRequest] = useState(false);
  const [confirmPickup, setConfirmPickup] = useState({});

  const [deleteZone, setDeleteZone] = useState();
  const [editUser, setEditUser] = useState();
  const [usersList, setUsersList] = useState([]);
  const [delCount, setDelCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [error, setError] = useState();
  const [listError, setListError] = useState();

  const [form, setForm] = useState({});

  const auth = useSelector((state) => state.auth);

  const getUsers = () => {
    setListLoading(true);
    setListError(false);
    authService
      .list()
      .then((resp) => {
        setUsersList(resp.data.users);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
        console.log(err);
      });
  };
  const handleDeleteZone = (id) => {
    setListLoading(true);
    setListError(false);
    setOpenConfirmModal(false);
    pickupService
      .deleteZone(id)
      .then((resp) => {
        getUsers();
        setDeleteZone();
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
        console.log(err);
      });
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div className="">
      <div className="flex space-x-4 my-4">
        <h1 className="text-2xl mb-4 flex-1">Users</h1>
        {delCount > 0 && (
          <button className="flex space-x-2 text-red-600 hover:text-gray-800 py-2 px-3 rounded focus:outline-none focus:shadow-outline text-sm items-center">
            <TrashIcon className="h-4 w-4" />
            <p>Delete {delCount} selected</p>
          </button>
        )}
        <button
          onClick={() => {
            setOpenZoneModal(true);
            setEditUser();
          }}
          className="flex space-x-2 items-center bg-black hover:bg-gray-800 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
        >
          <PlusIcon className="h-4 w-4" />
          <p>Create User</p>
        </button>
      </div>
      <div className="w-full overflow-x-scroll">
        <table className="table-auto w-full mb-3 border border-gray-200">
          <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
            <tr>
              <th className="p-2">
                <div className="font-semibold text-left">Name</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Login</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Role</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Action</div>
              </th>
            </tr>
          </thead>

          <tbody className="text-sm divide-y divide-gray-100">
            {listLoading ? (
              <tr>
                <td colSpan={5} className="">
                  <ArrowPathIcon className="h-6 w-6 mx-auto animate-spin" />
                </td>
              </tr>
            ) : listError ? (
              <tr>
                <td colSpan={5} className="">
                  <p className="text-center">{listError}</p>
                </td>
              </tr>
            ) : (
              usersList.map((zone, index) => (
                <tr key={index}>
                  <td className="p-2">
                    <div className="text-left">
                      {zone.first_name} {zone.last_name}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="font-medium text-gray-800">
                      {zone.email}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="font-medium text-gray-800">{zone.role}</div>
                  </td>
                  <td className="p-2 flex items-center gap-2">
                    <div className="bg-white border rounded-md">
                      <button
                        onClick={() => {
                          setOpenZoneModal(true);
                          setEditUser(zone);
                        }}
                        className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-md"
                      >
                        <PencilIcon className="h-4 w-4" />
                        <p>Edit</p>
                      </button>
                    </div>
                    <div className="bg-white border rounded-md">
                      <button
                        onClick={() => {
                          setDeleteZone(zone);
                          setOpenConfirmModal(true);
                        }}
                        className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-md"
                      >
                        <XMarkIcon className="h-4 w-4" />
                        <p>Delete</p>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <small className="text-right">Showing {usersList.length} results</small>

      {openConfirmModal && (
        <div
          className={`relative z-10`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <BellAlertIcon className="w-6 h-6" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-lg font-medium leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Confirm action?
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete {deleteZone.name}?
                          This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={() => handleDeleteZone(deleteZone.id)}
                    disabled={loading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {!loading ? (
                      "Continue"
                    ) : (
                      <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => setOpenConfirmModal(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {openZoneModal && (
        <AddUserModal
          close={() => setOpenZoneModal(false)}
          getUsers={getUsers}
          editUser={editUser}
        />
      )}
    </div>
  );
}
export default Users;
