import {
  ArrowPathIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import validator from "validator";
import CustomerService from "../services/customer.service";

const AddressPicker = (props) => {
  const { type, close, customer, selectedAddresses, setSelectedAddresses } =
    props;
  const [form, setForm] = useState({
    default: false,
    customerId: customer.id,
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const ref = useRef(null);

  const [create, setCreate] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleClickOutsideModal = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  const handleSubmitAddress = () => {
    setLoading(true);
    setForm({ ...form, customerId: customer.id });
    CustomerService.newCustomerAddress(customer.id, form)
      .then((resp) => {
        setCreate(false);
        getAddresses();
      })
      .catch((err) => {
        setError(err.toString());
        setLoading(false);
      });
  };

  const getAddresses = () => {
    setLoading(true);
    CustomerService.getCustomerAddresses(customer.id)
      .then((resp) => {
        setAddresses(resp.addresses);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.toString());
        setLoading(false);
      });
  };

  useEffect(() => {
    getAddresses();
    document.addEventListener("click", handleClickOutsideModal, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideModal, true);
    };
  }, []);

  return (
    <div
      className={`py-12 bg-gray-700 bg-opacity-40 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0`}
    >
      <div
        ref={ref}
        role="alert"
        className={`container mx-auto w-11/12 md:w-2/3 max-w-lg`}
      >
        {create ? (
          <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            {loading && (
              <div className="absolute bg-white w-full h-full top-0 left-0 bg-opacity-70 flex items-center">
                <ArrowPathIcon className="h-8 w-8 text-black mx-auto animate-spin" />
              </div>
            )}
            <div className="text-gray-800 text-2xl font-bold mb-3 flex items-center space-x-2 ">
              <ChevronLeftIcon
                className="h-6 w-6 cursor-pointer"
                onClick={() => {
                  setCreate(false);
                }}
              />
              <span>Add New Address</span>
            </div>
            {error && (
              <div
                className="flex items-center space-x-2 mb-3 bg-red-500 bg-opacity-80 text-white text-sm font-bold px-4 py-3"
                role="alert"
              >
                <InformationCircleIcon className="h-4 w-4" />
                <p>{error}</p>
              </div>
            )}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label
                  htmlFor="firstName"
                  className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                >
                  Firstname
                </label>
                <input
                  name="firstName"
                  onChange={(e) => handleChange(e)}
                  defaultValue={form.firstName}
                  className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder="James"
                />
              </div>
              <div className="flex-1">
                <label
                  htmlFor="lastName"
                  className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                >
                  Lastname (optional)
                </label>
                <input
                  name="lastName"
                  onChange={(e) => handleChange(e)}
                  defaultValue={form.lastName}
                  className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder="Nwafor"
                />
              </div>
            </div>
            <label
              htmlFor="phone"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Phone Number
            </label>
            <div className="relative mb-5 mt-2">
              <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                +234
              </div>
              <input
                name="phone"
                onChange={(e) => handleChange(e)}
                defaultValue={form.phone}
                className="text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-20 text-sm border-gray-300 rounded border"
                placeholder="XXXXXXXXXXX"
              />
            </div>
            <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
              Address
            </label>
            <input
              name="address1"
              onChange={(e) => handleChange(e)}
              className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
              placeholder="Address"
            />
            <input
              name="address2"
              onChange={(e) => handleChange(e)}
              className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
              placeholder="Landmark/area"
            />
            <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
              City
            </label>
            <input
              name="city"
              onChange={(e) => handleChange(e)}
              className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
              placeholder="City"
            />
            <div className="space-x-2 mb-2">
              <input
                type="checkbox"
                name="default"
                onChange={(e) => setForm({ ...form, default: !form.default })}
              />
              <span>Set as default</span>
            </div>
            <button
              onClick={() => handleSubmitAddress()}
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition duration-150 ease-in-out hover:bg-gray-800 bg-black rounded text-white px-8 py-2 text-sm"
            >
              Submit
            </button>
            <button
              onClick={close}
              className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
        ) : loading ? (
          <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
          </div>
        ) : (
          <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            <div className="text-gray-800 text-2xl font-bold mb-3">
              Select {type === "drop" ? "Drop Off" : "Return"} Address
            </div>
            <ul className="my-2 space-y-2">
              {addresses.map((address, index) => (
                <li
                  key={index}
                  onClick={() =>
                    setSelectedAddresses({
                      ...selectedAddresses,
                      [type]: address,
                    })
                  }
                  className={`border py-3 px-4 cursor-pointer hover:bg-gray-100 hover:border-gray-300 ${
                    selectedAddresses[type]?.id === address.id
                      ? "relative border-gray-800"
                      : "border-gray-200"
                  }`}
                >
                  {selectedAddresses[type]?.id === address.id && (
                    <CheckCircleIcon className="h-6 w-6 absolute right-2 top-2" />
                  )}
                  <p className="font-bold">
                    {address.firstName} {address.lastName}
                  </p>
                  <p>
                    {address.address1}, {address.address2}
                  </p>
                  <p>{address.city}</p>
                  <small>+234{address.phone}</small>
                </li>
              ))}
            </ul>
            <div className="flex items-center justify-start w-full">
              <button
                onClick={() => {
                  setCreate(true);
                  setForm({
                    ...form,
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    phone: customer.phone,
                  });
                }}
                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition duration-150 ease-in-out hover:bg-gray-800 bg-black rounded text-white px-8 py-2 text-sm"
              >
                Add Address
              </button>
            </div>
            <button
              onClick={close}
              className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default AddressPicker;
