import React, { useRef } from "react";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import OrderService from "../services/order.service";
import Compressor from "compressorjs";

const DragAndDrop = (props) => {
  const { data, dispatch, id } = props;
  const uploadRef = useRef(null);
  const handleUploadClick = () => {
    uploadRef.current.click();
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth + 1 });
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth - 1 });
    if (data.dropDepth > 0) return;
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        maxWidth: 1080,
        quality: 0.8,
        success: (result) => {
          resolve(new File([result], file.name, { type: result.type }));
        },
        error: (error) => reject(error),
      });
    });
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = [...e.dataTransfer.files];

    if (files && files.length > 0) {
      const existingFiles = data.fileList[id] ? data.fileList[id] : [];
      files = files.filter(
        (f) => !existingFiles.map((f) => f.name).includes(f.name)
      );
      files = files.filter((f) => f.type.match("image.*"));

      // collect promises from the compression function
      const compressPromises = [];
      const compressedImg = [];
      for (const file of files) {
        compressPromises.push(compressImage(file));
      }
      Promise.all(compressPromises)
        .then((compressedFiles) => {
          for (const file of compressedFiles) {
            // do whatever you need to do with these files - upload to server or whatever
            compressedImg.push(file);
          }
          const joinFiles = {
            ...data.fileList,
            [id]: existingFiles.concat(compressedImg),
          };

          dispatch({ type: "ADD_FILE_TO_LIST", joinFiles });
          e.dataTransfer.clearData();
        })
        .catch((error) => console.log("ooops :(", error));

      dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
    }
  };
  const handleAddFiles = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = [...e.target.files];

    if (files && files.length > 0) {
      const existingFiles = data.fileList[id] ? data.fileList[id] : [];
      files = files.filter(
        (f) => !existingFiles.map((f) => f.name).includes(f.name)
      );
      files = files.filter((f) => f.type.match("image.*"));

      // collect promises from the compression function
      const compressPromises = [];
      const compressedImg = [];
      for (const file of files) {
        compressPromises.push(compressImage(file));
      }
      Promise.all(compressPromises)
        .then((compressedFiles) => {
          for (const file of compressedFiles) {
            // do whatever you need to do with these files - upload to server or whatever
            compressedImg.push(file);
          }
          const joinFiles = {
            ...data.fileList,
            [id]: existingFiles.concat(compressedImg),
          };

          dispatch({ type: "ADD_FILE_TO_LIST", joinFiles });
        })
        .catch((error) => console.log("ooops :(", error));
    }
  };
  const handleDeleteFile = (index) => {
    dispatch({ type: "DELETE_FILE", index: index });
  };
  return (
    <main className="container mx-auto">
      <article
        aria-label="File Upload Modal"
        className="relative h-full flex flex-col bg-white"
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDrop={(e) => handleDrop(e)}
      >
        {data.inDropZone && (
          <div
            id="overlay"
            className="w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md bg-white bg-opacity-50"
          >
            <i>
              <CloudArrowUpIcon className="h-12 w-12" />
            </i>
            <p className="text-lg">Drop files to upload</p>
          </div>
        )}
        <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
          <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
            <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
              <span>Drag and drop your</span>&nbsp;
              <span>files anywhere or</span>
            </p>
            <input
              ref={uploadRef}
              type="file"
              accept="image/*,capture=camera"
              multiple
              onChange={(e) => handleAddFiles(e)}
              className="hidden"
            />
            <button
              onClick={handleUploadClick}
              className="mt-2 rounded-sm px-3 py-2 bg-gray-200 hover:bg-gray-300 focus:outline-none"
            >
              Select Files
            </button>
          </header>

          <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
            To Upload
          </h1>

          <ul id="gallery" className="flex flex-1 flex-wrap -m-1">
            {data.fileList[id]?.length > 0 ? (
              data.fileList[id].map((file, index) => (
                <li
                  className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/4 xl:w-1/4"
                  key={index}
                >
                  <article
                    tabIndex="0"
                    className="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm"
                  >
                    <img
                      alt={file.name}
                      className="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
                      src={URL.createObjectURL(file)}
                    />

                    <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3 hover:bg-black hover:bg-opacity-20">
                      <h1 className="flex-1">{file.name}</h1>
                      <div className="flex">
                        <span className="p-1">
                          <i>
                            <svg
                              className="fill-current w-4 h-4 ml-auto pt-"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z" />
                            </svg>
                          </i>
                        </span>

                        <p className="p-1 size text-xs">
                          {file.size > 1024
                            ? file.size > 1048576
                              ? Math.round(file.size / 1048576) + "mb"
                              : Math.round(file.size / 1024) + "kb"
                            : file.size + "b"}
                        </p>
                        <button
                          className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
                          onClick={() => handleDeleteFile(index)}
                        >
                          <svg
                            className="pointer-events-none fill-current w-4 h-4 ml-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              className="pointer-events-none"
                              d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                            />
                          </svg>
                        </button>
                      </div>
                    </section>
                  </article>
                </li>
              ))
            ) : (
              <li
                id="empty"
                className="h-full w-full text-center flex flex-col items-center justify-center items-center"
              >
                <img
                  className="mx-auto w-32"
                  src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                  alt="no data"
                />
                <span className="text-small text-gray-500">
                  No files selected
                </span>
              </li>
            )}
          </ul>
        </section>
      </article>
    </main>
  );
};
export default DragAndDrop;
