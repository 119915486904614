import React, {
  forwardRef,
  useState,
  useReducer,
  useEffect,
  useRef,
} from "react";
import {
  ArrowPathIcon,
  XMarkIcon,
  ChevronUpDownIcon,
  ChevronLeftIcon,
  PencilIcon,
  EyeIcon,
} from "@heroicons/react/24/solid";
import { format } from "date-fns";
import DragAndDrop from "../../components/DragAndDrop";
import { useOutletContext, useParams } from "react-router-dom";
import OrderService from "../../services/order.service";

function OrderItem() {
  let { order } = useParams();
  const ref = useRef(null);

  const notify = useOutletContext();
  const [orderLoading, setOrderLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [readyLoading, setReadyLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showServiceSelect, setShowServiceSelect] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [services, setServices] = useState([]);
  const [notes, setNotes] = useState("");
  const [items, setItems] = useState([]);

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: action.joinFiles };
      case "CLEAR_FILES":
        return { ...state, fileList: action.files };
      case "DELETE_FILE":
        return {
          ...state,
          fileList: state.fileList.filter(
            (file) => file !== state.fileList[action.index]
          ),
        };
      default:
        return state;
    }
  };
  const handleServiceSelect = (index) => {
    let copyServices = services.map((s, i) => {
      if (i === index) {
        s.selected = !s.selected;
      }
      return s;
    });
    setServices(copyServices);
  };

  const handleClickOutsideSearch = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowServiceSelect(false);
    }
  };

  const handleClearFiles = () => {
    const files = { ...data.fileList, new: [] };
    dispatch({ type: "CLEAR_FILES", files });
  };
  const handleUpload = async (id) => {
    setUploadLoading(true);
    var form = new FormData();
    data.fileList[id].map((file) => form.append("images", file));

    let selServ = [];
    services.map((service) => {
      service.selected === true && selServ.push(service.code);
    });
    form.append("services", selServ);
    form.append("notes", notes);
    OrderService.createOrderItem(form, order)
      .then((resp) => {
        setUploadLoading(false);
        getOrderItems();
        getServices();
        setShowAdd(false);
        handleClearFiles();
      })
      .catch((err) => {
        setUploadLoading(false);
        notify("error", "Error", err.toString());
      });
  };
  const getOrder = () => {
    setOrderLoading(true);
    OrderService.getOrder(order)
      .then((resp) => {
        setOrderDetails(resp);
        getOrderItems();
        setOrderLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOrderLoading(false);
        notify("error", "Error", err.toString());
      });
  };
  const getOrderItems = () => {
    setOrderLoading(true);
    OrderService.getOrderItems(order)
      .then((resp) => {
        setItems(resp.items);
        setOrderLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOrderLoading(false);
        notify("error", "Error", err.toString());
      });
  };

  const updateOrderItemStatus = (item) => {
    setReadyLoading(true);
    OrderService.updateOrderItemStatus(order, item, { status: "processed" })
      .then((resp) => {
        getOrderItems();
        setReadyLoading(false);
        console.log(resp);
      })
      .catch((err) => {
        setReadyLoading(false);
        console.log(err);
        notify("error", "Error", err.toString());
      });
  };
  const deleteOrderItem = (item) => {
    setDeleteLoading(true);
    OrderService.deleteOrderItem(order, item)
      .then((resp) => {
        getOrderItems();
        setDeleteLoading(false);
        console.log(resp);
      })
      .catch((err) => {
        setDeleteLoading(false);
        console.log(err);
        notify("error", "Error", err.toString());
      });
  };

  const getServices = () => {
    OrderService.getServices()
      .then((resp) => {
        setServices(resp.services);
      })
      .catch((err) => {
        notify("error", "Error", err.toString());
      });
  };
  const handleAccordionToggle = (index) => {
    const cloneItems = items.map((c, i) => {
      if (i === index) {
        c.open = !c.open;
      } else {
        c.open = false;
      }
      return c;
    });
    setItems(cloneItems);
  };
  const [data, dispatch] = useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: {},
  });

  useEffect(() => {
    getOrder();
    getServices();
    document.addEventListener("click", handleClickOutsideSearch, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideSearch, true);
    };
  }, []);

  return (
    <div className="col-span-2 flex flex-col space-y-2">
      <div className="accordion" id="accordionExample">
        {items.length === 0 && (
          <h1 className="mb-3 text-center">No item has been added yet.</h1>
        )}
        {items.map((item, index) => (
          <div
            className="accordion-item bg-white border border-gray-200 mb-2"
            key={index}
          >
            <h2 className="accordion-header mb-0" id="headingOne">
              <button
                className="accordion-button relative flex justify-between items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none"
                type="button"
                onClick={() => handleAccordionToggle(index)}
              >
                <p
                  className={item.status === "processed" ? "line-through" : ""}
                >
                  Item #{orderDetails.number}-{index + 1}
                </p>
                <div className="flex flex-wrap justify-end gap-1">
                  {item.services.map((servic, index) => (
                    <p
                      key={index}
                      className="text-xs font-semibold py-1 px-2 uppercase rounded text-green-600 bg-green-200 uppercase"
                    >
                      {
                        services.filter((service) => service.code === servic)[0]
                          ?.name
                      }
                    </p>
                  ))}
                </div>
              </button>
            </h2>
            <div
              className={`accordion-collapse ${!item.open && "hidden"}`}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5">
                <main className="container mx-auto">
                  <article className="relative h-full flex flex-col bg-white">
                    <section className="h-full overflow-auto p-2 w-full h-full flex flex-col">
                      <h1 className="pb-3 font-semibold sm:text-lg text-gray-900">
                        Photos
                      </h1>
                      <ul className="flex flex-1 flex-wrap -m-1">
                        {item.photos.map((photo, index) => (
                          <li
                            className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/4 xl:w-1/4"
                            key={index}
                          >
                            <a
                              href={photo.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <article
                                tabIndex="0"
                                className="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm"
                              >
                                <img
                                  alt={"file.name"}
                                  className="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
                                  src={photo.url}
                                />
                              </article>
                            </a>
                          </li>
                        ))}
                      </ul>
                      <h1 className="py-3 font-semibold sm:text-lg text-gray-900">
                        Notes
                      </h1>
                      <p>{item.notes}</p>
                      {item.status !== "processed" && (
                        <div className="space-x-2">
                          <button
                            onClick={() => updateOrderItemStatus(item.id)}
                            className="px-3 py-2 bg-black hover:bg-gray-500 text-white focus:shadow-outline focus:outline-none"
                          >
                            {readyLoading ? (
                              <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
                            ) : (
                              "Mark Ready"
                            )}
                          </button>
                          <button
                            onClick={() => deleteOrderItem(item.id)}
                            className="px-3 py-2 bg-red-600 hover:bg-red-500 text-white focus:shadow-outline focus:outline-none"
                          >
                            {deleteLoading ? (
                              <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
                            ) : (
                              "Delete"
                            )}
                          </button>
                        </div>
                      )}
                    </section>
                  </article>
                </main>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showAdd ? (
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingOne">
            <div
              className="accordion-button relative flex space-x-4 items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none"
              type="button"
            >
              <p className="flex-1">New Item</p>
              <button onClick={() => setShowAdd(false)} type="button">
                <XMarkIcon className="h-4 w-4" />
              </button>
            </div>
          </h2>
          <div
            id="collapseOne"
            className={`accordion-collapse`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body py-4 px-5">
              <DragAndDrop data={data} dispatch={dispatch} id={"new"} />
              <div className="w-full flex flex-col mx-auto">
                <div className="flex flex-col items-center relative">
                  <div className="w-full  svelte-1l8159u">
                    <div className="my-2 p-1 flex border border-gray-200 bg-white rounded svelte-1l8159u">
                      <div className="flex flex-auto flex-wrap">
                        {services.map((service, index) => {
                          if (service.selected) {
                            return (
                              <div
                                key={index}
                                className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 "
                              >
                                <div className="text-xs font-normal leading-none max-w-full flex-initial">
                                  {service.name}
                                </div>
                                <div className="flex flex-auto flex-row-reverse">
                                  <div>
                                    <XMarkIcon
                                      onClick={() => handleServiceSelect(index)}
                                      className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}

                        <div className="flex-1">
                          <input
                            placeholder={
                              services.filter(
                                (service) => service.selected === true
                              ).length < 1
                                ? "Select services"
                                : ""
                            }
                            onClick={() =>
                              setShowServiceSelect(!showServiceSelect)
                            }
                            className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                          />
                        </div>
                      </div>
                      <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200 svelte-1l8159u">
                        <button
                          className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none"
                          onClick={() =>
                            setShowServiceSelect(!showServiceSelect)
                          }
                        >
                          <ChevronUpDownIcon className="feather feather-chevron-up w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto max-h-48 ${
                      !showServiceSelect && "hidden"
                    }`}
                  >
                    <div ref={ref} className="flex flex-col w-full">
                      {services.map((service, index) => (
                        <div
                          key={index}
                          onClick={() => handleServiceSelect(index)}
                          className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                        >
                          <div
                            className={`flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative ${
                              service.selected && "border-teal-600"
                            }`}
                          >
                            <div className="w-full items-center flex">
                              <div className="mx-2 leading-6  ">
                                {service.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <label className="my-3">Note(s):</label>
                <textarea
                  className="border border-gray-200 h-24"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                ></textarea>
              </div>
              {/* {data.fileList[id]?.length ? ( */}
              <footer className="flex justify-end px-8 pb-2 pt-4">
                <button
                  onClick={() => handleUpload("new")}
                  disabled={uploadLoading}
                  className="px-3 py-2 bg-black hover:bg-gray-500 text-white focus:shadow-outline focus:outline-none"
                >
                  {uploadLoading ? (
                    <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
                  ) : (
                    "Upload now"
                  )}
                </button>
                <button
                  onClick={handleClearFiles}
                  className="ml-3 rounded-sm px-3 py-2 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                >
                  Cancel
                </button>
              </footer>
              {/* ) : (
          <></>
        )} */}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <button
            onClick={() => {
              setShowAdd(true);
              handleAccordionToggle("new");
            }}
            className="px-3 py-2 bg-black hover:bg-gray-500 text-white focus:shadow-outline focus:outline-none"
          >
            Add Item
          </button>
        </div>
      )}
    </div>
  );
}
const ButtonInput = forwardRef(({ value, onClick }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
  >
    {format(new Date(value), "dd MMMM yyyy")}
  </button>
));
export default OrderItem;
