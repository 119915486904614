import axios from "axios";
import { logout } from "../actions/auth";
import authHeader from "./auth-header";
import store from "../store";

const { dispatch } = store; // direct access to redux store.
const API_URL = process.env.REACT_APP_API_URL;
export const loginAPI = axios.create({
  baseURL: API_URL + "auth",
});

export const userAPI = axios.create({
  baseURL: API_URL,
});

userAPI.interceptors.request.use((config) => {
  config.headers["x-access-token"] = authHeader();

  return config;
});
userAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    if (error.response.status === 401) {
      dispatch(logout());
    }
  }
);
export default userAPI;
