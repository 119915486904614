import userAPI from "./index.service";

class PickupService {
  getRequests(status, page, request) {
    return userAPI
      .get("requests", {
        params: { status, page, request, perPage: 20 },
      })
      .then((response) => {
        return response.data;
      });
  }
  getRequest(request) {
    return userAPI.get("requests/" + request).then((response) => {
      return response.data;
    });
  }
  updateRequestStatus(requestId, status) {
    return userAPI.put("requests/" + requestId, status).then((response) => {
      return response.data;
    });
  }
  updateRequest(data) {
    return userAPI.put(`${"requests/" + data.id}`, data).then((response) => {
      return response.data;
    });
  }
  createRequest(data) {
    return userAPI.post(`requests`, data).then((response) => {
      return response.data;
    });
  }

  createZone(data) {
    return userAPI.post(`logistics/zones`, data).then((response) => {
      return response.data;
    });
  }
  getZones() {
    return userAPI.get("logistics/zones").then((response) => {
      return response.data;
    });
  }
  getLocations() {
    return userAPI.get("logistics/locations").then((response) => {
      return response.data;
    });
  }
  updateZone(data) {
    return userAPI
      .put(`${"logistics/zones/" + data.id}`, data)
      .then((response) => {
        return response.data;
      });
  }
  deleteZone(data) {
    return userAPI.delete(`${"logistics/zones/" + data}`).then((response) => {
      return response.data;
    });
  }
}

export default new PickupService();
