import { loginAPI } from "./index.service";

class AuthService {
  login(email, password) {
    return loginAPI.post("login", { email, password }).then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
  }
  update(request) {
    return loginAPI.put("/"+request.id, request).then((response) => {
   
      return response.data;
    });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(request) {
    return loginAPI.post("register", request);
  }

  list() {
    return loginAPI.get("list");
  }
}

export default new AuthService();
