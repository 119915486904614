import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";

export default function DropdownComponent(props) {
  const { setConfirm, setConfirmOrder, order, className } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  const handleClickOutsideDropDown = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropDown, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropDown, true);
    };
  }, []);
  return (
    <div className={className}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 text-sm hover:text-gray-300 rounded-l-md"
      >
        Mark as Paid
        <ChevronDownIcon className="h-4 w-4" />
      </button>

      <div
        ref={dropDownRef}
        className={`${
          isOpen ? "" : "hidden"
        } absolute right-0 z-10 w-28 mt-2 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg`}
      >
        <button
          onClick={() => {
            setConfirm(true);
            setConfirmOrder({ order, paymentMethod: "cash" });
          }}
          className="block w-full px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
        >
          Cash
        </button>
        <button
          onClick={() => {
            setConfirm(true);
            setConfirmOrder({ order, paymentMethod: "card" });
          }}
          className="block w-full px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
        >
          Card
        </button>
        <button
          onClick={() => {
            setConfirm(true);
            setConfirmOrder({ order, paymentMethod: "transfer" });
          }}
          className="block w-full px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
        >
          Transfer
        </button>
      </div>
    </div>
  );
}
