import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./view/Auth/Login";
import CreateOrder from "./view/Dashboard/CreateOrder";
import CreatePickup from "./view/Dashboard/CreatePickup";
import Customers from "./view/Dashboard/Customers";
import Dashboard from "./view/Dashboard/Dashboard";
import EditOrder from "./view/Dashboard/EditOrder";
import EditPickup from "./view/Dashboard/EditPickup";
import Home from "./view/Dashboard/Home";
import Logistics from "./view/Dashboard/Logistics";
import Orders from "./view/Dashboard/Orders";
import PickupRequests from "./view/Dashboard/PickupRequests";
import Users from "./view/Dashboard/Users";
import ViewOrder from "./view/Dashboard/ViewOrder";
import ViewPickup from "./view/Dashboard/ViewPickup";

function App() {
  return (
    <Routes>
      <Route path="/auth/login" element={<Login />} />{" "}
      <Route element={<Dashboard />}>
        <Route path="/dashboard/home" element={<Home />} />
        <Route path="/dashboard/customers" element={<Customers />} />
        <Route path="/dashboard/pickups" element={<PickupRequests />} />
        <Route path="/dashboard/logistics" element={<Logistics />} />
        <Route path="/dashboard/pickup/create" element={<CreatePickup />} />
        <Route path="/dashboard/pickup/:requestId" element={<ViewPickup />} />
        <Route
          path="/dashboard/pickup/:requestId/edit"
          element={<EditPickup />}
        />
        <Route path="/dashboard/orders" element={<Orders />} />
        <Route path="/dashboard/order/create" element={<CreateOrder />} />
        <Route
          path="/dashboard/order/create/:pickup"
          element={<CreateOrder />}
        />
        <Route path="/dashboard/order/:order" element={<ViewOrder />} />
        <Route path="/dashboard/order/:order/edit" element={<EditOrder />} />
        <Route path="/dashboard/users" element={<Users />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/auth/login" />} />
    </Routes>
  );
}

export default App;
