import React, { forwardRef, useState, useEffect, useRef } from "react";
import {
  TrashIcon,
  UserCircleIcon,
  PlusIcon,
  ShoppingCartIcon,
  MinusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  CalendarDaysIcon,
  XMarkIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import validator from "validator";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import OrderService from "../../services/order.service";
import OrderTotalCalculator from "../../components/OrderTotalCalculator";
import AddCustomerModal from "../../components/AddCustomerModal";
import AddressPicker from "../../components/AddressPicker";
import PickupService from "../../services/pickup.service";

function CreatePickup() {
  const [dropFee, setdropFee] = useState(0);
  const [returnFee, setreturnFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [addressModal, setAddressModal] = useState("");
  const [selectedAddresses, setSelectedAddresses] = useState({});
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [collectionData, setCollectionData] = useState({
    dropType: "walk-in",
    dropDate: new Date(),
  });
  const [servicesList, setServicesList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [preferredDate, setPreferredDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [customer, setCustomer] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [openNewCModal, setOpenNewCModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pairsEstimate, setPairsEstimate] = useState(2);

  const navigate = useNavigate();

  const ref = useRef(null);

  const handleCreateRequest = (confirm) => {
    setLoading(true);
    const request = {
      address: selectedAddresses.drop,
      source: "STORE",
      customerId: customer.id,
      pairsEstimate: pairsEstimate,
      preferredDate: preferredDate.toISOString().split("T")[0], // ISO 8601 format : YYYY-MM-DD
    };
    console.log(request);
    PickupService.createRequest(request)
      .then((resp) => {
        setLoading(false);
        navigate("/dashboard/pickups");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClickOutsideSearch = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenSuggestions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSearch, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideSearch, true);
    };
  }, []);
  const getServices = () => {
    OrderService.getServices()
      .then((resp) => {
        setServicesList(resp.services);
      })
      .catch((err) => {});
  };

  const getAddresses = (customer) => {
    CustomerService.getCustomerAddresses(customer)
      .then((resp) => {
        // setAddress(resp);
        let defaultAddress = resp.addresses.filter(
          (address) => address.default
        );
        console.log(resp.addresses);
        setSelectedAddresses({
          drop: defaultAddress[0],
        });
      })
      .catch((err) => {});
  };
  const findCustomer = (q) => {
    setOpenSuggestions(true);
    CustomerService.getCustomers(q, 10, 1)
      .then((resp) => {
        setSearchCustomers(resp.customers);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getServices();
  }, []);
  return (
    <div className="">
      <h1 className="text-2xl my-4 ">New Request</h1>
      <div className="flex flex-col xl:flex-row w-full lg:w-3/4 2xl:w-2/3 mx-auto gap-2 justify-center">
        <div className="w-full xl:w-2/3 flex flex-col space-y-2">
          <div className="border border-gray-300 p-5">
            {!customer ? (
              <div className="flex space-x-2">
                <div className="relative flex-1">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">
                      <UserCircleIcon className="h-4 w-4" />
                    </span>
                  </div>
                  <input
                    placeholder="Find Customer"
                    value={searchQuery}
                    className="w-full border border-gray-300 p-2 pl-8"
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      findCustomer(e.target.value);
                    }}
                  />
                  {openSuggestions && (
                    <div className="absolute bg-white z-10 w-full shadow-md mt-2 max-h-52 overflow-auto">
                      <ul className="" ref={ref}>
                        {searchCustomers.map((customer, index) => (
                          <li
                            key={index}
                            onClick={(e) => {
                              setCustomer(customer);
                              getAddresses(customer.id);
                              setOpenSuggestions(false);
                            }}
                            className="flex flex-col hover:bg-gray-100 px-4 py-3"
                          >
                            <p>
                              {customer.firstName} {customer.lastName}
                            </p>
                            <small className="text-gray-600">
                              {customer.email}
                            </small>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <button
                  className="bg-black text-white p-2"
                  onClick={() => setOpenNewCModal(true)}
                >
                  <PlusIcon className="h-5 w-5" />
                </button>
              </div>
            ) : (
              <div className="">
                <div className="flex justify-between">
                  <h1 className="text-lg font-medium">
                    {customer.firstName} {customer.lastName}
                  </h1>
                  <button onClick={() => setCustomer()}>
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </div>
                <p>{customer.phone}</p>
                <small>{customer.email}</small>
              </div>
            )}
          </div>
          {customer && (
            <div className="border border-gray-300 p-5">
              <div className="flex space-x-2">
                <div className="w-full flex flex-col gap-2">
                  <h4 className="text-lg mb-2">Pickup Details</h4>

                  <p className="mb-2 font-bold">Date</p>
                  <div className="relative w-full mb-2">
                    <DatePicker
                      selected={preferredDate}
                      name="preferredDate"
                      onChange={(date) => {
                        setPreferredDate(date);
                      }}
                      selectsStart
                      startDate={preferredDate}
                      minDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      popperClassName="react-datepicker-left"
                      customInput={<ButtonInput />}
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div className="flex items-center justify-between px-2 py-2">
                          <span className="text-lg text-gray-700">
                            {format(date, "MMMM yyyy")}
                          </span>

                          <div className="space-x-2">
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                              type="button"
                              className={`
                                            ${
                                              prevMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                            >
                              <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                            </button>

                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                              type="button"
                              className={`
                                            ${
                                              nextMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                            >
                              <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <p className="mb-2 font-bold">Address</p>
                  {selectedAddresses.drop ? (
                    <div>
                      <p className="font-bold">
                        {selectedAddresses.drop.firstName}{" "}
                        {selectedAddresses.drop.lastName}
                      </p>
                      <p>
                        {selectedAddresses.drop.address1},{" "}
                        {selectedAddresses.drop.address2}
                      </p>
                      <p>{selectedAddresses.drop.city}</p>
                      <p>+234{selectedAddresses.drop.phone}</p>
                      <button
                        className="text-sm text-red-600 hover:underline"
                        onClick={() => setAddressModal("drop")}
                      >
                        Change
                      </button>
                    </div>
                  ) : (
                    <div>
                      <p>No default address</p>
                      <button
                        className="text-sm text-red-600 hover:underline"
                        onClick={() => setAddressModal("drop")}
                      >
                        Select
                      </button>
                    </div>
                  )}
                  <p className="mb-2 font-bold"># of Pairs</p>

                  <input
                    name="pairsEstimate"
                    onChange={(e) => setPairsEstimate(e.target.value)}
                    defaultValue={pairsEstimate}
                    placeholder="10"
                    className="w-full border border-gray-300 p-2"
                  />
                </div>
              </div>
              {/* <div className="flex items-center justify-center w-full mx-auto space-x-2 mt-2">
            
            </div> */}
            </div>
          )}

          <button
            onClick={() => handleCreateRequest(true)}
            disabled={!customer || loading}
            className="bg-black w-full text-white p-2 mt-2 text-center disabled:opacity-75"
          >
            {loading ? (
              <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
            ) : (
              "Create"
            )}
          </button>
        </div>
        <AddCustomerModal
          open={openNewCModal}
          close={() => setOpenNewCModal(false)}
          getCustomers={() => findCustomer("")}
        />

        {addressModal !== "" && (
          <AddressPicker
            type={addressModal}
            customer={customer}
            selectedAddresses={selectedAddresses}
            setSelectedAddresses={setSelectedAddresses}
            close={() => setAddressModal("")}
          />
        )}
      </div>
    </div>
  );
}
const ButtonInput = forwardRef(({ value, onClick }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
  >
    {format(new Date(value), "dd MMMM yyyy")}
  </button>
));
export default CreatePickup;
