import userAPI from "./index.service";

class OrderService {
  getServices() {
    return userAPI.get("services").then((response) => {
      return response.data;
    });
  }
  getOrders(status, page, order, customerId) {
    return userAPI
      .get("orders", {
        params: { status, page, order, perPage: 20, customerId },
      })
      .then((response) => {
        return response.data;
      });
  }
  getOrder(order) {
    return userAPI.get("orders/" + order).then((response) => {
      return response.data;
    });
  }
  getDueOrders() {
    return userAPI.get("orders/due").then((response) => {
      return response.data;
    });
  }
  getOrderActivity(order) {
    return userAPI.get("orders/" + order + "/activity").then((response) => {
      return response.data;
    });
  }
  getOrderItems(orderId) {
    return userAPI.get("orders/" + orderId + "/items").then((response) => {
      return response.data;
    });
  }
  updateOrderItemStatus(orderId, itemId, status) {
    return userAPI
      .put("orders/" + orderId + "/items/" + itemId + "/status", status)
      .then((response) => {
        return response.data;
      });
  }
  deleteOrderItem(orderId, itemId) {
    return userAPI
      .delete("orders/" + orderId + "/items/" + itemId)
      .then((response) => {
        return response.data;
      });
  }
  createOrder(data, confirm) {
    return userAPI
      .post(`orders${confirm ? "?confirmed=1" : ""}`, data)
      .then((response) => {
        return response.data;
      });
  }
  createOrderItem(data, orderId) {
    return userAPI
      .post("orders/" + orderId + "/items", data)
      .then((response) => {
        return response.data;
      });
  }
  updateOrder(data) {
    return userAPI.post(`${"orders/" + data.id}`, data).then((response) => {
      return response.data;
    });
  }
  payOrder(data, id) {
    return userAPI.put(`${"orders/" + id + "/pay"}`, data).then((response) => {
      return response.data;
    });
  }
  bulkUpdate(data) {
    return userAPI.put("orders/bulk", data).then((response) => {
      return response.data;
    });
  }
}

export default new OrderService();
